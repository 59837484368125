<template>
  <DeviceGraph :title=title :yAxisTitle=yAxisTitle :type=type :series0=series0 :series1=series1 :values0=values0 :values1=values1 :upside1=true />
</template>

<script>
import DeviceGraph from "device/status/modules/DeviceGraph.vue"

export default {

  name: "DoubleGraph",

  components: {DeviceGraph},
  
  props: {
  
    title: String, 
    yAxisTitle: String,
  
    type: {
      type: String,
      default: "line",
    },
  
    series0: {
      type: Object,
      default() { return {} },
    },
    series1: {
      type: Object,
      default() { return {} },
    },

    values0: {
      type: Object,
      default() { return {start: 0, step: 0, data: []} },
    },
    values1: {
      type: Object,
      default() { return {start: 0, step: 0, data: []} },
    },
    
  },

  data()
  {
    return {
      
    }
  },
  
}
</script>

<style>
.highcharts-container {
 display: inline-block !important; 
}
</style>


<template>
<div>
  <template v-if="this.state.port">
    <form class="form-horizontal">
      <Select :options=periodOptions v-model="period" label="Период" :labelSize=5 :size=3 />
    <br/>
    </form>
    <div style="overflow: hidden;text-align: center" class="hcdiv">
      <DoubleGraph type="area" :title="'Трафик' + portName" yAxisTitle="Трафик (бит/c)" 
        :series0=inputDesc :values0=input :series1=outputDesc :values1=output />
      <DoubleGraph type="area" :title="'Ошибки' + portName" yAxisTitle="Ошибки (пак/c)" 
        :series0=inerrorsDesc :values0=inerrors :series1=outerrorsDesc :values1=outerrors />
      <DoubleGraph type="area" :title="'Сброшенные пакеты' + portName" yAxisTitle="Пакеты/c" 
        :series0=indiscardDesc :values0=indiscard :series1=outdiscardDesc :values1=outdiscard />
      <DoubleGraph type="area" :title="'Unicast' + portName" yAxisTitle="Пакеты/c" 
        :series0=inucastDesc :values0=inucast :series1=outucastDesc :values1=outucast />
      <DoubleGraph type="area" :title="'Multicast' + portName" yAxisTitle="Пакеты/c" 
        :series0=inmucastDesc :values0=inmucast :series1=outmucastDesc :values1=outmucast />
      <DoubleGraph type="area" :title="'Broadcast' + portName" yAxisTitle="Пакеты/c" 
        :series0=inbrcastDesc :values0=inbrcast :series1=outbrcastDesc :values1=outbrcast />
    </div>
  </template>
  <p class="text-center " v-else>
    <strong>Выберите порт...</strong>
  </p>
</div>
</template>

<script>
import Select from "common/visual/input/Select.vue"
import DoubleGraph from "device/status/modules/DoubleGraph.vue"
import darsan from "darsan"
import radio from "radio"

export default {

  name: "TrafficBoard",

  components: {Select, DoubleGraph},

  data()
  {
    return {
      state: {},
      
      period: "day2",
      
      periodOptions:  [
        { value: 'hour6', text: '6 часов' },
        { value: 'hour12', text: '12 часов' },
        { value: 'day', text: 'Сутки' },
        { value: 'day2', text: 'Двое суток' },
        { value: 'week', text: 'Неделя' },
        { value: 'month', text: 'Месяц' },
      ],
      
      portTags: {},
      
      dialogOpened: true,
      
      inputDesc: {
         name: "Входящий",
         color: "green",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " бит/c",
         },
       },
       
      outputDesc: {
         name: "Исходящий",
         color: "blue",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " бит/c",
         },
       },

      inerrorsDesc: {
         name: "Ошибки RX",
         color: "orange",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " пак/c",
         },
       },
       
      outerrorsDesc: {
         name: "Ошибки TX",
         color: "red",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " пак/c",
         },
       },

      indiscardDesc: {
         name: "Входящие",
         color: "mediumpurple",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outdiscardDesc: {
         name: "Исходящие",
         color: "goldenrod",
         tooltip: {
           valueDecimals: 0,
         },
       },

      inucastDesc: {
         name: "Входящие",
         color: "mediumpurple",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outucastDesc: {
         name: "Исходящие",
         color: "goldenrod",
         tooltip: {
           valueDecimals: 0,
         },
       },

      inmucastDesc: {
         name: "Входящие",
         color: "khaki",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outmucastDesc: {
         name: "Исходящие",
         color: "firebrick",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
       inbrcastDesc: {
         name: "Входящие",
         color: "darkgreen",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outbrcastDesc: {
         name: "Исходящие",
         color: "darkcyan",
         tooltip: {
           valueDecimals: 0,
         },
       },


      input: { start: 0, step: 0, data: [] },
      output: { start: 0, step: 0, data: [] },
      inerrors: { start: 0, step: 0, data: [] },
      outerrors: { start: 0, step: 0, data: [] },
      indiscard: { start: 0, step: 0, data: [] },
      outdiscard: { start: 0, step: 0, data: [] },
      inbrcast: { start: 0, step: 0, data: [] },
      outbrcast: { start: 0, step: 0, data: [] },
      inucast: { start: 0, step: 0, data: [] },
      outucast: { start: 0, step: 0, data: [] },
      inmucast: { start: 0, step: 0, data: [] },
      outmucast: { start: 0, step: 0, data: [] },
    }
  },
  
  created()
  {
    const me = this
    radio.channel("poller").on('poller.poll-completed', function() { if (me.dialogOpened) me.loadValues() })
    radio.channel("device:ui").on("dialog-opened", function(val) { me.dialogOpened = val})
  },
  
  methods: {
    async loadValues()
    {
      if (!this.state.port) return
      const data = await darsan.get("", "device", `/${this.state.type}/${this.state.device}/port/${this.state.port}/data/traffic?period=${this.period}`)
      
      this.input.start = this.output.start = this.inerrors.start = this.outerrors.start = this.indiscard.start = this.outdiscard.start = data.start
      this.input.step = this.output.step = this.inerrors.step = this.outerrors.step = this.indiscard.step = this.outdiscard.step = data.step
      this.inbrcast.start = this.outbrcast.start = this.inucast.start = this.outucast.start = this.inmucast.start = this.outmucast.start = data.start
      this.inbrcast.step = this.outbrcast.step = this.inucast.step = this.outucast.step = this.inmucast.step = this.outmucast.step = data.step
      
      this.input.data = data.series.input
      this.output.data = data.series.output
      this.inerrors.data = data.series.inerrors
      this.outerrors.data = data.series.outerrors
      this.indiscard.data = data.series.indiscard
      this.outdiscard.data = data.series.outdiscard

      this.inbrcast.data = data.series.inbrcast
      this.outbrcast.data = data.series.outbrcast
      this.inucast.data = data.series.inucast
      this.outucast.data = data.series.outucast
      this.inmucast.data = data.series.inmucast
      this.outmucast.data = data.series.outmucast
    }
  },
  
  computed: {
    portName()
    {
      return (this.state.port ? " - порт " + this.state.port : "") + (this.portTags[this.state.port] ? " (" + this.portTags[this.state.port] + ")" : "")
    }
  },
  
  watch: {
    state:
    {
      handler: async function(val) 
      { 
        this.loadValues() 

        const port = +val.port || 1

        if (!this.portTags[port])
        {
          const row = await darsan.get("", "device", `/${this.state.type}/${this.state.device}/port/${port}/tag`)
          this.$set(this.portTags, port, row.tag)
        }
      },
    },
    period:
    {
      handler: function(val) { this.loadValues() },
      immediate: false,
    },
  },

}
</script>

<style>
.hcdiv .highcharts-container {
  display: inline-block !important;
}
</style>


define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',

    'common/visual/visual',
    'common/dialog/dialog',
    'device/common',
    'common',

    'text-loader!device/status/modules/mac/row.tpl',
    'text-loader!device/status/modules/mac/table.tpl',
    'device/status/modules/mac_info/module'
  ],
  function(
    $,
    _,
    Backbone,
    darsan,
    visual,
    dialog,
    common_dev,
    common,
    rowTemplate,
    tableTemplate,
    mac_info_module
  ) {
    var advData;
    var prefix;

    var mModel = Backbone.Model.extend({
      initialize: function() {
        var me = this;

	me.ws = [];
        this.on('ping', function() {

	  _.map(me.ws, function(v){ v.close() });
	  me.ws = [];

	  _.forEach(me.get('clients'), function(v){

		if(!v.ip) return;

        	v.ping_result = [];
		me.trigger('change');

        	let ws = new WebSocket(config.ping_server + '/ip/' + v.ip + '?packet=1400');
        	ws.onclose = function(e){};

    		var k = 0;
        	ws.onmessage = function(e) {
        	    k++;

        	    v.ping_result.push(JSON.parse(e.data));
        	    me.trigger('change');

        	    if (k >= 5) {
            		return ws.close();
        	    }
		};

		me.ws.push(ws);
	 });

/*
          me.ws = new WebSocket(config.ping_server + '/ip/' + me.get('ip') + '?packet=1400');
          me.ws.onclose = function(e) {};

          var k = 0,
            array = [];
          me.set({ ping_result: array });

          me.ws.onmessage = function(e) {
            k++;
            array.push(JSON.parse(e.data));

            me.set({ ping_result: array }, { silent: true });
            me.trigger('change:ping_result change');

            if (k >= 5) {

              me.ws.close();
              delete me.ws;
              return;

            }
          };
*/
        });
      }
    });

    var collection = Backbone.Collection.extend({
      model: mModel,
      initialize: function(array, param) {
        this.smodel = param.smodel;

        this.on('error', function(a, e) {
          var data = JSON.parse(e.responseText) || {};
          common.notifyError(data.text_ru || data.text);
        });
      }
    });

    var rowView = Backbone.View.extend({
      tagName: 'tr',
      template: _.template(rowTemplate),
      initialize: function() {
        this.listenTo(this.model, 'change', this.render);
      },
      render: function() {
        var type = this.model.collection.smodel.get('type');
        this.$el.html(
          this.template({
            model: this.model.toJSON(),
            advData: advData,
            common: common,
            type: type
          })
        );
        return this;
      },
      toPing: function(e) {
        e.preventDefault();
//        if (this.model.ws) return;
        this.model.trigger('ping');
      },
      toPingResult: function(e) {
        e.preventDefault();
//        if (this.model.ws) return;

	_.forEach(this.model.get('clients'), function(v){
	    delete v.ping_result;
	});
        this.model.trigger('change');
      },
      toUid: function() {
        //Закрываем предыдущий
//	dialog.close('device-status-window');
	this.$el.closest('.ui-dialog-content').dialog('close');

      },
      toMac: function(e) {
        e.preventDefault();

        dialog.close('device-info');
        dialog.showModule(
          mac_info_module,
          { id: 'device-info', width: '1100px' },
          { mac: this.model.get('mac') }
        );
        $('#device-info')
          .closest('.ui-dialog')
          .css({ position: 'fixed', top: '100px' });

      },
      toInterface: function(e) {
        e.preventDefault();
        dialog.showModule(
          mac_info_module,
          { id: 'device-info', width: '1100px' },
          { mac: this.model.get('mac') }
        );
        $('#device-info')
          .closest('.ui-dialog')
          .css({ position: 'fixed', top: '100px' });
      },
      events: {
        'click a#mac': 'toMac',
        'click a#uid': 'toUid',
        'click a#ping': 'toPing',
        'click a#interface': 'toInterface',
        'click a#ping_result': 'toPingResult'
      }
    });

    var mainView = Backbone.View.extend({
      template: _.template(tableTemplate),
      initialize: function(param) {
        var me = this;
        this.collection.on('sync reset sort', me.render, this);

        var smodel = me.collection.smodel;
        this.collection.on('sync reset sort', function() {

          me.$('.sort').removeClass('sort_up sort_down');
          me.$('.sort[attr='+smodel.get('sort')+']').addClass(smodel.get('dir') == 1 ? 'sort_up' : 'sort_down');

        });
      },
      render: function() {
        var me = this;

        if (!this.oldView) this.oldView = {};
        _.each(
          this.oldView,
          function(v) {
            v.$el.detach();
          },
          this
        );

        var type = this.collection.smodel.get('type');
        this.$el.html(this.template({ type: type }));

        //Подключаем виевы
        var currView = {},
          array = [];

        //Отображение коллекции
        var param = this.collection._param;
        this.collection.forEach(function(model) {

          var view = this.oldView[model.cid] || new rowView({ model: model }).render();
          currView[model.cid] = view;
          array.push(view.el);
/*
	  if( model.get('other') ){
		_.forEach(model.get('other'), function(v){
			var model  = new mModel( v );
			var view = this.oldView[model.cid] || new rowView({ model: model }).render();

			view.$el.addClass('other');

			currView[model.cid] = view;
			array.push(view.el);
		}, this);
	  }
*/
        }, this);

        //Вставляем контент
        this.$('tbody').html(array);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );
        this.oldView = currView;

        return this;
      },
      toSort: function(e) {
        e.preventDefault();
        e.stopPropagation();

        var target = e.currentTarget;
        var smodel = this.collection.smodel;
        var attr = target.getAttribute('attr'), dir = smodel.get('dir');

        switch (dir) {
          case 1:
            dir = 0;
            break;
          case 0:
            dir = undefined;
            break;
          default:
            dir = 1;
            break;
        }

        if (dir == undefined) {
          smodel.unset('sort', { silent: true });
          smodel.unset('dir', { silent: true });
        } else {
          smodel.set({ sort: attr, dir: dir }, { silent: true });
        }

        this.collection.comparator = function(m1, m2) {
          if (dir == undefined) return 1;

         var v1 = m1.get(attr) || '', v2 = m2.get(attr) || '';
	 if( _.contains(['port', 'vlan'], attr )){
	    v1 = parseInt(v1);
	    v2 = parseInt(v2);
	 }
	 if( _.contains(['login', 'ip', 'duration'], attr )){
	    if(m1.get('clients')) v1 = (_.first(m1.get('clients'))||{})[attr];
	    if(m2.get('clients')) v2 = (_.first(m2.get('clients'))||{})[attr];
	 }

          if (v1 == v2) return 0;

          if (dir == 1) {
            return v1 > v2 ? 1 : -1;
          } else {
            return v1 < v2 ? 1 : -1;
          }
        };

        this.collection.sort();
      },
      toPingAll: function(e) {
        e.preventDefault();
        this.collection.map(function(model) {
          model.trigger('ping');
        });
      },
      events: {
        'click .sort': 'toSort',
        'click a#ping_all': 'toPingAll'
      }
    });

    return Object.create(visual).extend({
      title: 'MAC',
      name: 'deviceFdb',
      icon: 'hdd',
      deferred: [],
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        this.smodel = new Backbone.Model();
        this.collection = new collection(null, { smodel: this.smodel });

        this.view = new mainView({ collection: this.collection });
	this.view.setElement( this.$el );

	//Устраняем мигание/Очищаем коллекцию
        this.smodel.on('all', function(v) {
          if ( v == 'change:device' ) me.collection.reset();
	});

        this.smodel.on('change:device change:port', function(v, v3, v2){

	  //Отменяем предыдущие запросы
	  _.map(me.deferred, function(v){ v.fail() });
	  me.deferred = [];

	  //Очищаем коллекцию
	 if( !me.smodel.get('device') || !me.smodel.get('port') ){
		me.collection.reset();
		return;
    	  }

          //Получаем fdb со всех портов
          var a = [];
          _.forEach(String(me.smodel.get('port')).split(','), function(v) {
            me.deferred.push( darsan.get(prefix, 'device','/' + me.smodel.get('type') + '/' + me.smodel.get('device') + '/port/' + v + '/fdb').done(function(b) {
                  Array.prototype.push.apply(a, b);
            }));
          });

          //Обьединяем результаты и находим сесии и пользователей
          $.when.apply($, me.deferred).done(function() {
            var coll = _.sortBy(a, function(v){ return parseInt(v.port); });
            var macs = [];

            //Сливаем старые и новые данные
            _.forEach(coll, function(v) {
              var model = me.collection.findWhere({ mac: v.mac });
              if (model) _.extend(v, model.toJSON());

              macs.push(v.mac);
            });
            me.collection.reset(coll);

            macs = _.compact(macs);
            if (_.isEmpty(macs)) return;

            //Ищем пользователей по макам
            darsan.post(prefix, 'client', '/clients-from-macs', { macs: macs.join(',').replace(/:/g, '') }).done(function(data) {
		me.collection.forEach(function(m){
		    m.set({ clients: _.where(data, { mac: m.get('mac') }) });
		});
		me.collection.sort();
              });
          });
        });
      },
      setState: function(state) {
        var me = this;

        me.state = state;
	prefix = state.prefix||config.domain;

        this.smodel.set(me.state);

      }
    });
  }
);

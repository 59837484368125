define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'darsanCacheRadix',
    'common',
    'sysconf',
    'preload',
    'radio',

    'permission',

    'common/visual/visual',
    'location-input',

    'text-loader!device/tag-editor/layout.tpl'
  ],
  function(
    $,
    _,
    Backbone,
    darsan,
    darsanCache,
    common,
    sysconf,
    preload,
    radio,
    perm,
    Visual,
    locInput,
    template
  ) {
    'use strict';

    return Object.create(Visual).extend({
      title: 'Данные',
      name: 'clientInfo',
      icon: 'user',

      create: function(el, opt) {
        var me = this;
        Visual.create.apply(me, arguments);


        var TagModel = Backbone.ModelEx.extend({
          // idAttribute: "entity",
          url: function() { 
	    return darsan.makeURL(config.domain, "device" ,"/"+me.state.type+"/"+ me.state.id + "/tag") 
	    }
        });

        me.model = new TagModel({id: me.state && me.state.uid || 0 });

        var pppoeView = Backbone.TemplateCooperView.extend({
          events: {
          },

          template: template,

          extraRender: function() {},
        });

        me.view = new pppoeView({
          model: me.model,
          el: me.$el,
          module: me
        });

        return preload.ensure('device-tags');
      },

      setState: function(state) {
        var me = this;
        if (me.state && me.state.id == state.id && me.state.type == state.type) return;

        me.state = state;

        me.clientTags = preload.get(config.domain, 'device-tags');
        me.model.id = me.state.id;
        me.model.type = me.state.type;

        me.model.fetch()
          .always(function () {
            me.view.render();
          })
      }
    });
  }
);

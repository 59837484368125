var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      { staticClass: "form-horizontal" },
      [
        _c("Select", {
          attrs: {
            options: _vm.periodOptions,
            label: "Период",
            labelSize: 5,
            size: 4
          },
          model: {
            value: _vm.period,
            callback: function($$v) {
              _vm.period = $$v
            },
            expression: "period"
          }
        }),
        _vm._v(" "),
        _c("br")
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "hcdiv",
        staticStyle: { overflow: "hidden", "text-align": "center" }
      },
      [
        _c("DeviceGraph", {
          attrs: {
            type: "line",
            title: "Уровни сигнала",
            yAxisTitle: "dBm",
            series0: _vm.onurxDesc,
            values0: _vm.onurx,
            series1: _vm.onutxDesc,
            values1: _vm.onutx,
            series2: _vm.oltrxDesc,
            values2: _vm.oltrx
          }
        }),
        _vm._v(" "),
        _c("DeviceGraph", {
          attrs: {
            type: "line",
            title: "Температура",
            yAxisTitle: "Градусы С",
            series0: _vm.tempDesc,
            values0: _vm.temp
          }
        }),
        _vm._v(" "),
        _c("DoubleGraph", {
          attrs: {
            type: "area",
            title: "Трафик",
            yAxisTitle: "Трафик (бит/c)",
            series0: _vm.inputDesc,
            values0: _vm.input,
            series1: _vm.outputDesc,
            values1: _vm.output
          }
        }),
        _vm._v(" "),
        _c("DoubleGraph", {
          attrs: {
            type: "area",
            title: "Ошибки",
            yAxisTitle: "Ошибки (пак/c)",
            series0: _vm.inerrorsDesc,
            values0: _vm.inerrors,
            series1: _vm.outerrorsDesc,
            values1: _vm.outerrors
          }
        }),
        _vm._v(" "),
        _c("DoubleGraph", {
          attrs: {
            type: "area",
            title: "Сброшенные пакеты",
            yAxisTitle: "Пакеты",
            series0: _vm.indiscardDesc,
            values0: _vm.indiscard,
            series1: _vm.outdiscardDesc,
            values1: _vm.outdiscard
          }
        }),
        _vm._v(" "),
        _c("DoubleGraph", {
          attrs: {
            type: "area",
            title: "Unicast",
            yAxisTitle: "Пакеты",
            series0: _vm.inucastDesc,
            values0: _vm.inucast,
            series1: _vm.outucastDesc,
            values1: _vm.outucast
          }
        }),
        _vm._v(" "),
        _c("DoubleGraph", {
          attrs: {
            type: "area",
            title: "Multicast",
            yAxisTitle: "Пакеты",
            series0: _vm.inmucastDesc,
            values0: _vm.inmucast,
            series1: _vm.outmucastDesc,
            values1: _vm.outmucast
          }
        }),
        _vm._v(" "),
        _c("DoubleGraph", {
          attrs: {
            type: "area",
            title: "Broadcast",
            yAxisTitle: "Пакеты",
            series0: _vm.inbrcastDesc,
            values0: _vm.inbrcast,
            series1: _vm.outbrcastDesc,
            values1: _vm.outbrcast
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',

    'common/visual/visual',
    'common/dialog/dialog',
    'device/common',
    'common',

    'text-loader!device/status/modules/mac_old/row.tpl',
    'text-loader!device/status/modules/mac_old/table.tpl',
    'device/status/modules/mac_info/module'
  ],
  function(
    $,
    _,
    Backbone,
    darsan,
    visual,
    dialog,
    common_dev,
    common,
    rowTemplate,
    tableTemplate,
    mac_info_module
  ) {

    var prefix;
    var advData;
    var model = Backbone.Model.extend({});

    var collection = Backbone.Collection.extend({
      model: model,
      initialize: function(array, param) {
        this.smodel = param.smodel;
      }
    });

    var rowView = Backbone.View.extend({
      tagName: 'tr',
      template: _.template(rowTemplate),
      initialize: function() {
        this.listenTo(this.model, 'change', this.render);
      },
      render: function() {
        this.$el.html(
          this.template({ model: this.model.toJSON(), advData: advData, common: common })
        );
        return this;
      },
      toUid: function() {
        //Закрываем предыдущий
	this.$el.closest('.ui-dialog-content').dialog('close');
      },
      toMac: function(e) {
        e.preventDefault();

        dialog.close('device-info');
        dialog.showModule(
          mac_info_module,
          { id: 'device-info', width: '1100px' },
          { mac: this.model.get('mac') }
        );

        $('#device-info')
          .closest('.ui-dialog')
          .css({ position: 'fixed', top: '100px' });
      },
      events: {
        'click a#mac': 'toMac',
        'click a#uid': 'toUid'
      }
    });

    var mainView = Backbone.View.extend({
      initialize: function(param) {
        var me = this;
        this.$el.html(tableTemplate);
        this.collection.on('sync reset sort', me.render, this);

        var smodel = me.collection.smodel;
        this.collection.on('sync reset sort', function() {
          me.$('.sort').removeClass('sort_up sort_down');
          me.$('.sort[attr='+smodel.get('sort')+']').addClass(smodel.get('dir') == 1 ? 'sort_up' : 'sort_down');
        });
      },
      render: function() {
        if (!this.oldView) this.oldView = {};
        _.each(
          this.oldView,
          function(v) {
            v.$el.detach();
          },
          this
        );

        var me = this;

        //Подключаем виевы
        var currView = {},
          array = [];

        //Отображение коллекции
        var param = this.collection._param;
        this.collection.forEach(function(model) {
          var view = this.oldView[model.cid] || new rowView({ model: model }).render();
          currView[model.cid] = view;
          array.push(view.el);
        }, this);

        //Вставляем контент
        this.$('tbody').html(array);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );
        this.oldView = currView;

        return this;
      },
      toSort: function(e) {
        e.preventDefault();
        e.stopPropagation();

        var target = e.currentTarget;
        var smodel = this.collection.smodel;
        var attr = target.getAttribute('attr'),
          dir = smodel.get('dir');

        switch (dir) {
          case 1:
            dir = 0;
            break;
          case 0:
            dir = undefined;
            break;
          default:
            dir = 1;
            break;
        }

        if (dir == undefined) {
          smodel.unset('sort', { silent: true });
          smodel.unset('dir', { silent: true });
        } else {
          smodel.set({ sort: attr, dir: dir }, { silent: true });
        }

        this.collection.comparator = function(m1, m2) {
          if (dir == undefined) return 1;

          var v1 = m1.get(attr) || '', v2 = m2.get(attr) || '';
	  if( _.contains(['port', 'vlan'], attr )){
	    v1 = parseInt(v1);
	    v2 = parseInt(v2);
	  }
	  if( _.contains(['login', 'ip', 'duration'], attr )){
	    if(m1.get('clients')) v1 = (_.first(m1.get('clients'))||{})[attr];
	    if(m2.get('clients')) v2 = (_.first(m2.get('clients'))||{})[attr];
	  }

          if (v1 == v2) return 0;

          if (dir == 1) {
            return v1 > v2 ? 1 : -1;
          } else {
            return v1 < v2 ? 1 : -1;
          }
        };

        this.collection.sort();
      },
      events: {
        'click .sort': 'toSort'
      }
    });

    return Object.create(visual).extend({
      title: 'MAC (Old)',
      name: 'deviceFdbOld',
      icon: 'hdd',
      deferred: [],
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        this.smodel = new Backbone.Model();
        this.collection = new collection(null, { smodel: this.smodel });

        this.view = new mainView({ collection: this.collection });
        this.$el.html(this.view.el);

	//Устраняем мигание/Очищаем коллекцию
        this.smodel.on('all', function(v) {
          if ( v == 'change:device' ) me.collection.reset();
	});

        this.smodel.on('change:device change:port change:sort', function() {

	  //Отменяем предыдущие запросы
	  _.map(me.deferred, function(v){ v.fail() });
	  me.deferred = [];

	  if( !me.smodel.get('device') || !me.smodel.get('port') ){
            me.collection.reset();
            return;
          }

	  var a = [];
          _.forEach(String(me.smodel.get('port')).split(','), function(v) {
            me.deferred.push( darsan.get(prefix,'device','/' + me.smodel.get('type') + '/' + me.smodel.get('device') + '/port/' + v + '/oldmacs').done(function(b) {
                  Array.prototype.push.apply(a, b);
            }));
          });


          //Обьединяем результаты и находим сесии и пользователей
          $.when.apply($, me.deferred).done(function() {
            var coll = _.sortBy(a, function(v) { return parseInt(v.port); });
            var macs = [];

            //Сливаем старые и новые данные
            _.forEach(coll, function(v) {
              var model = me.collection.findWhere({ mac: v.mac });
              if (model) _.extend(v, model.toJSON());

              macs.push(v.mac);
              //			if( !v.duration ) macs.push( v.mac );
            });

            me.collection.reset(coll);

            //Ищем пользователей по макам
            if (_.isEmpty(macs)) return;
            darsan.post(prefix, 'client', '/clients-from-macs', { macs: macs.join(',').replace(/:/g, '') })
              .done(function(data) {
                _.map(data, function(v) {

                    var model = me.collection.findWhere({ mac: v.mac });
                    if (model){ 
			model.set(v);

			var other = {} 
			_.map(_.where(data, { mac: v.mac }), function(v2){ 

			    if( v.login == v2.login ) return;
			    if( !other[v2.login] ) other[v2.login] = v2;
			    if( !other[v2.login].duration ) other[v2.login] = v2;
			});
			if( _.size(other) > 1 ) model.set({ other: other });
		    }

/*
		    //Ищем абонента с мак адресом
                    var model = me.collection.findWhere({ mac: v.mac });
                    if (model){ 
			if(!model.get('login')){ 
			    model.set(v);
			}else if(!me.collection.findWhere({ login: v.login })){
			    me.collection.add(v);
			}
		    }
*/

                });
//                me.collection.trigger('reset');
                me.collection.sort();

              });
          });
        });
      },
      setState: function(state) {
        var me = this;
        me.state = state;
	prefix = state.prefix||config.domain;

        delete me.state.tabs;
        this.smodel.set(me.state);
      }
    });
  }
);

define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'store',
    'common/visual/visual',
    'common/search/grammar',
    'navigation',
    'permission',
    'common',
    'radio',
    'common/dialog/dialog',
    'common/table/table_adv',
    'common/context/context',
    'location-input',
    '_lib/select_adv/select_adv',
    'device/common',

    'text-loader!device/page/mainTpl.tpl',
    'text-loader!device/page/filterTpl.tpl',
    'text-loader!device/page/switchMainTpl.tpl',
    'text-loader!device/page/switchRowTpl.tpl',
    'text-loader!device/page/smartMainTpl.tpl',
    'text-loader!device/page/smartRowTpl.tpl',
    'text-loader!device/page/ponMainTpl.tpl',
    'text-loader!device/page/ponRowTpl.tpl',
    'text-loader!device/page/ponTreeMainTpl.tpl',
    'text-loader!device/page/ponTreeRowTpl.tpl',
    'text-loader!device/page/editTpl.tpl',
    'text-loader!device/page/groupRowTpl.tpl',
    'text-loader!device/page/nodeRowTpl.tpl',
    'text-loader!device/page/devRowTpl.tpl',

    'device/status/device-status',
    'device/map/device-map',
    'device/tag-editor/tag_editor',
    'device/svlan-cvlan-editor/svlan-cvlan_editor',
    'device/status/modules/mac_info/module'
  ],
  function(
    $,
    _,
    Backbone,
    darsan,
    store,
    visual,
    parser,
    navigation,
    perm,
    common,
    radio,
    dialog,
    TableAdv,
    context,
    locInput,
    select_adv,
    common_dev,
    mainTpl,
    filterTpl,
    switchMainTpl,
    switchRowTpl,
    smartMainTpl,
    smartRowTpl,
    ponMainTpl,
    ponRowTpl,
    ponTreeMainTpl,
    ponTreeRowTpl,
    editTpl,
    groupRowTpl,
    nodeRowTpl,
    devRowTpl,
    statusModule,
    mapModule,
    tagModule,
    svlanCvlanModule,
    mac_info_module
  ) {

    var advData = common_dev.advData;

    var model = Backbone.Model.extend({
      idAttribute: 'entity',
      initialize: function() {
	this.on('error', function(a, e) {
            var data = JSON.parse(e.responseText) || {};
            common.notifyError('"' + data.name + '" ' + (data.text_ru || data.text));
	},this);
	this.on('change:entity add', function(){
	    this.set('photo_url', this.url()+'/photo?darsan2='+encodeURIComponent(localStorage.getItem('radix-token')));
	});
      }
    });

    var collection = Backbone.Collection.extend({
      model: model,
      initialize: function(array, param) {

        this.type = param.type;
        this.url = param.url;
        this.fmodel = param.fmodel; //Модель с фильтрами
        this.smodel = new Backbone.Model(); //Модель с сортировкой и пагинацией

        this.on('error', function(a, e) {
          // Ибо нефиг отмененным запросам плевать ошибки юзерам
          if (e.status == 0) return;

          var data = JSON.parse(e.responseText) || {};
          common.notifyError(data.text_ru || data.text);
        });
      },

      fetch: function(request) 
      {
        var me = this;
        request = _.extend({ data: {} }, request);

        //Отменяем предыдущий xhr запрос
        if (this.xhr) 
        {
          this.xhr.abort()
          store.default.commit("delAjaxCall")
        }
        
        this.xhr = new window.XMLHttpRequest();

        //Обьект xhr для отмены запросов
        request.xhr = function() {
          return me.xhr;
        };

        //Параметры из фильтров и сортировки
        var param = {};
        _.extend(param, _.clone(this.fmodel.toJSON()));
        _.extend(param, _.clone(this.smodel.toJSON()));

      	if ( this.type == 'pontree' ) delete param['state'];

        //Сортировка
        if (param.sort) {
          request.data.sort = param.dir ? param.sort : '-' + param.sort;
      	  param = _.omit(param, ['sort', 'dir']);
        }

        //Пагинация
        if (param.paged) {
          _.extend(request.data, { paged: 1, from: param.from, count: param.count });
	        param = _.omit(param, ['paged', 'from', 'count', 'total', 'page']);
        }

        //Поиск
        if (!_.isEmpty(param)) {
          var query = [];
          _.map(param, function(v, k) {
    	    if(k == 'node'){
              query.push(k + ' IN (' + v + ')');
            }else if (v.match(/\*/)) {
              query.push(k + ' LIKE "' + v + '"');
            } else {
              query.push(k + '="' + v + '"');
            }

          });
          request.data.query = query.join(' AND ');
        }

        return Backbone.Collection.prototype.fetch.call(this, request);
      },
    });

    var editView = Backbone.View.extend({
      template: _.template(editTpl),
      render: function() {
        var model = this.model.toJSON(), me = this;
        this.$el.html(this.template({ model: model, advData: advData, type: this.type, perm: perm }));

	//Редактор связи с локацией
	if( _.contains(['pon', 'switch', 'smart'], this.type )){
	    locInput.create(null, null, this.$el.find('[name=location]'));
	}
	//Редактор выбора головного устройства для pontree
	if( _.contains(['pontree'], this.type )){
	    select_adv(this.$el.find('[name=head]'), { data: _.map(advData.pon, function(v){ 
		return { id: v.entity, name: v.name }} )
	    });
	}

        if (!this.model.isNew()) {

	  //Редактор svlan-clvan
          svlanCvlanModule.create(this.$('#svlanCvlanEditor'));
          svlanCvlanModule.setState({ url: darsan.makeUrl('', 'device', '/'+me.type+'/' + model.entity + '/svlan') });

	   //Редактор тегов
	  if( _.contains(['pon', 'switch', 'smart', 'pontree'], this.type )){
            tagModule.create(this.$('#tagEditor')).done(function(){
		tagModule.setState({ id: model.entity, type: me.type });
	    });
	  }
        }

        return this;
      },
      toFree: function(e) {
        var me = this;
	var name = $(e.currentTarget).attr('name');

	if( name == 'ip' && this.model.get('ip')) return;
	if( name == 'vlan' && this.model.get('vlan')) return;

        var $eli = me.$('datalist#ipl'), $elv = me.$('datalist#vlanl');

        if (e.type == 'focusin') {
          darsan.get('', 'device', '/node/' + me.model.get('node') + '/next-free-address').done(function(data) {
              if (data.ip) $eli.html('<option value="' + data.ip + '">');
              if (data.vlan) $elv.html('<option value="' + data.vlan + '">');
            });
        }
        if (e.type == 'focusout') {
          $eli.empty();
          $elv.empty();
        }
      },
      toUpload: function(e) {
        e.preventDefault();
        var me = this;

        var formData = new FormData();
        formData.append('image', e.currentTarget.files[0]);

        darsan
          .formDataPutm(
            '',
            'device',
            '/' + me.type + '/' + me.model.get('entity') + '/photo',
            formData
          )
          .done(function() {
            me.animateSuccess(me.$('[name="photo_upload"]'));
            common.notify('Изображение успешно загружено');
          })
          .fail(function(e) {
            me.render();
            me.animateFail(me.$('[name="photo_upload"]'));
            darsan.error(e)
          });
      },
      toChange: function(e) {
        var el = $(e.currentTarget),
          data = {};
        if (el.attr('name') == 'photo_upload') return;

        if (el.attr('name') == 'location') {
          var val = String(el.val()).split('|');
          data = { location: val[0], location_name: val[1] };
        } else {
          data[el.attr('name')] = el.attr('type') == 'checkbox' ? el.is(':checked') : el.val();
        }

        var me = this;

        if (this.model.isNew()) {
          this.model.set(data);
        } else {
          this.model.save(data, {
            patch: true,
            success: function() {
              me.animateSuccess(el);
            },
            error: function() {
              me.animateFail(el);
            }
          });
        }
      },
      toCreate: function(e) {
        e.preventDefault();
        var me = this;

        this.collection.unshift(this.model);
        this.model.save(null, {
          error: function(model, xhr) {
            if (xhr.responseJSON) {
              me.animateFail(me.$('[name="' + xhr.responseJSON.name + '"]'));
            }
            me.collection.remove(me.model);
          }
        });
      },
      toAddType: function(e) {
        e.preventDefault();
        var me = this;

        var type = prompt('Название типа');
        if (!type) return;

        advData.types.push({ type: type });

        this.render();
        this.$('[name="type"]')
          .val(type)
          .trigger('change');
      },

      animate: function(el, _class) {
        el
          .removeClass(_class)
          .addClass(_class)
          .delay(1000)
          .queue(function() {
            $(this)
              .removeClass(_class)
              .dequeue();
          });
      },
      animateSuccess: function(el) {
        return this.animate(el, 'animate_success');
      },
      animateFail: function(el) {
        return this.animate(el, 'animate_fail');
      },
      toViewPassword: function(e){
	e.preventDefault();
	var me =this;
        darsan.get('', 'device', '/'+me.type+'/' +me.model.get('entity')+'/password').done(function(data) {
            alert(data.password);
	});
      },
      toTransform: function(e){
	var me = this;
	var to = $(e.target).attr('to');
        darsan.post('', 'device', '/transform-device/'+me.model.get('entity'), { to: to }).then(function(){

	    me.collection.fetch();
	    Backbone.history.navigate('/device/'+to+'/'+me.model.get('entity'), { replace: true, trigger: true });

	}, function(e){
	    var data = JSON.parse(e.responseText) || {};
	    common.notifyError('"' + data.name + '" ' + (data.text_ru || data.text));
	});
      },
      events: {
        'focusin input[name="ip"],input[name="access_vlan"]': 'toFree',
        'focusout input[name="ip"],input[name="access_vlan"]': 'toFree',
        'change input[name="photo_upload"]': 'toUpload',
        'submit form': 'toCreate',
        'click #add_type': 'toAddType',
        'click #view_password': 'toViewPassword',
        'click #transform': 'toTransform',
        'change select,input,textarea': 'toChange',
      }
    });

    var rowView = Backbone.View.extend({
      tagName: 'tr',
      initialize: function() {
	this.template = _.template(this.template);
	this.listenTo(this.model, 'change', this.render);

      },
      render: function() {
        switch (this.model.get('state')) {
          case 'unknown':
            this.$el.addClass('warning');
            break;
          case 'down':
            this.$el.addClass('danger');
            break;
          default:
            this.$el.removeClass('succees,warning,danger');
            break;
        }
        this.$el.html(this.template({ model: this.model.toJSON(), advData: advData, common: common }));
        return this;
      },
      toRemove: function(e) {
        e.preventDefault();
        if (!confirm('Удалить запись "' + this.model.get('entity') + '"?')) return;

        var coll = this.model.collection,
          model = this.model;
        model.destroy({
          success: function() {
            coll.remove(model);
          }
        });
      },
      toPhoto: function(e) {
        e.preventDefault();
        var d = dialog.show(
          'Изображение устройства',
          '<img class="img-rounded" style="width:100%;object-fit:contain;" src="' +
            this.model.url() +
            '/photo?darsan2=' +
            encodeURIComponent(localStorage.getItem('radix-token')) +
            '">',
          { id: 'photo', width: '900px' }
        );
      },
      toAdministerCon: function(e) {
        e.preventDefault();
        var el = $(e.currentTarget);

        var data = [],
          ip = this.model.get('ip');
        data.push({
          name: 'Открыть через http',
          func: function() {
            window.open('http://' + ip);
          }
        });

        data.push({
          name: 'Открыть через https',
          func: function() {
            window.open('https://' + ip);
          }
        });

        data.push({
          name: 'Открыть через telnet',
          func: function() {
            el.get(0).click();
          }
        });

        context.show(e, data);
      },
      events: {
        'click a#del': 'toRemove',
        'click a#photo': 'toPhoto',
        'contextmenu a#administer': 'toAdministerCon'
      }
    });

    var mainView = Backbone.View.extend({
      initialize: function(param) {
        var me = this;
        this.modelView = rowView;
        this.collection.on('sync remove', this.render, this);

        //Сортировка
        this.smodel = me.collection.smodel;
        this.smodel.on('change:sort', function() {
	  var $sr_el = me.$('.sort');
	  $sr_el.removeClass('sort_up sort_down')
	  $sr_el.filter('[attr=' + this.get('sort') + ']').addClass(this.get('dir') == 1 ? 'sort_up' : 'sort_down');
        });
      },
      paginator: function( param ){
	var array = [], range = 5, page = parseInt(this.smodel.get('page')), total = Math.ceil(this.smodel.get('total')/this.smodel.get('count'));
	var rl = Math.round( range/2 );

	for ( var i=1; i<=total; i++ ){
	    if( i == 1 || i == total ){
		array.push( i );
	    }else if( i - page < rl && page - i < rl ){
		array.push( i );
	    }else{
		var pp = ( i < page ) ? page - i : i - page;
		if( ( pp <= 10 && !( i % 10 )) || ( pp <= 100 && !( i % 100 )) || ( pp <= 1000 && !( i % 1000 )) ){
		    array.push( i );
		}
	    }
	}

	var frag = document.createDocumentFragment();
	 frag.appendChild($('<li class="cursor-pointer"><a data-page="'+(page-1)+'">«</a></li>').get(0));
	_.forEach(array, function(v){
	    if( page == v ){
		frag.appendChild($('<li class="cursor-pointer active"><span>'+v+'</span></li>').get(0));
	    }else{
		frag.appendChild($('<li class="cursor-pointer"><a data-page="'+v+'">'+v+'</a></li>').get(0));
	    }
	});
	frag.appendChild($('<li class="cursor-pointer"><a data-page="'+(page + 1)+'">»</a></li>').get(0));

	return frag;
      },
      render: function() {
        if (!this.oldView) this.oldView = {};
        var me = this;

        //Подключаем виевы
        var currView = {}, frag = document.createDocumentFragment();

        this.collection.forEach(function(model) {
	    var view = this.oldView[model.cid] || new me.modelView({ model: model }).render();
	    currView[model.cid] = view;
	    frag.appendChild(view.el);
        }, this);

	//Получаем el
	var el = this.el.getElementsByTagName('tbody')[0];

	//Очищаем старое
	el.innerHTML = '';

	//Вставляем новое
	el.appendChild(frag);

        //Уничтожаем неиспользуемые
        _.each(this.oldView, function(v, k) {
            if (!currView[k]) v.remove();
          },this);

        this.oldView = currView;
	this.$('ul.pagination').html(this.paginator());

        return this;
      },
      toPage: function(e) {
	  let page = $(e.currentTarget).data('page');
	  if( page < 1 || page > Math.ceil(this.smodel.get('total')/this.smodel.get('count'))) return;
	  this.smodel.set({ page: page }, { silent: true }).trigger('change:page');
      },
      toSort: function(e) {
        e.preventDefault();

        var target = e.currentTarget;
        var attr = target.getAttribute('attr'),
          dir = target.getAttribute('dir');
        var smodel = this.collection.smodel;

        switch (dir) {
          case '1':
            dir = 0;
            break;
          case '0':
            dir = undefined;
            break;
          default:
            dir = 1;
            break;
        }

        if (dir == undefined) {
          target.removeAttribute('dir');
          smodel.unset('sort', { silent: true });
          smodel.unset('dir', { silent: true }).trigger('change:sort');
        } else {
          target.setAttribute('dir', dir);
          smodel.set({ sort: attr, dir: dir }, { silent: true }).trigger('change:sort');
        }
      },
      events: {
        'click .sort': 'toSort',
        'click .pagination a': 'toPage',
      }
    });

    function parseRows(param) {
      if (!param.thead) throw Error(`Отсуствует обязательный параметр 'thead'`);
      if (!param.tbody) throw Error(`Отсуствует обязательный параметр 'tbody'`);

      var array = [];
      $(param.thead).find('th').each(function(k, v) {
          var el = $(v);
          array.push({ id: el.attr('attr'), name: el.text(), thead: _.unescape(v.outerHTML) });
        });

        $(param.tbody).not('#text').each(function(k, v) {
          array[k].tbody = _.unescape(v.outerHTML);
        });
	return array;
    }

    //Формирование групп и узлов
    var nodeModel = Backbone.Model.extend({
      idAttribute: 'entity',
      defaults: function(){ return { total: 0, up: 0, down: 0, unknown: 0, disabled: 0, name: '' } }
    });

    var devModel = Backbone.Model.extend({
      idAttribute: 'entity',
      defaults: function(){ return { count: 0, name: '' } }
    });

    var groupModel = Backbone.Model.extend({
      defaults: function(){ return { nodes: [], name: '' } }
    });

    var nodeColl = Backbone.Collection.extend({
    	model: nodeModel
    });

    var devColl = Backbone.Collection.extend({
    	model: devModel
    });

    var groupColl = Backbone.Collection.extend({
    	model: groupModel
    });

    var devRowView = Backbone.View.extend({
       className: 'device-group node list-group-item',
       tagName: 'li',
       template: _.template( devRowTpl ),
       initialize: function(param) {
	    var me = this;
	    this.model.on('change', this.render, this);
	},
	render: function(){
	    ( this.model.get('selected') ) ? this.$el.addClass('active') : this.$el.removeClass('active');

	    this.$el.html(this.template({ model: this.model.toJSON() }));
	    return this;
	},
	toClick: function(e){
	    e.stopPropagation();
	    e.preventDefault();

	    //Меняем URL
	    var data = { type: (( this.model.get('entity') == '*' ) ? '' :  this.model.get('entity')) };
	    _.extend(data, _.pick(common_dev.urlData.get(), ['sort', 'dir'] ));
	    common_dev.urlData.set(data);
	},
	events: {
	    'click': 'toClick'
	}
    });

    var nodeRowView = Backbone.View.extend({
       className: 'node-group node list-group-item',
       tagName: 'li',
       template: _.template( nodeRowTpl ),
       initialize: function(param) {
	    var me = this;
	    this.model.on('change', this.render, this);

	    if($(window).width() <= 1000) return;
	    this.$el.draggable({ 
		    helper: 'clone',
		    connectToSortable: "ul.device-gn",
		    cursor: "crosshair",
	    }).attr({ entity: this.model.get('entity') });
       },
	render: function(){
	    this.$el.html(this.template({ model: this.model.toJSON() }));

	    if( this.model.get('selected') ){
		this.$el.addClass('active');
		this.$('.state[state="'+this.model.get('selected')+'"]').addClass('active');
	    }else{
		this.$el.removeClass('active');
		this.$('.state').removeClass('active');
	    }

	    return this;
	},
	toClick: function(e){
	    e.stopPropagation();
	    e.preventDefault();

	    //Меняем URL
	    var data = { node: (( this.model.get('entity') == '*' ) ? '' :  this.model.get('entity')), state: $(e.currentTarget).attr('state') };
	    _.extend(data, _.pick(common_dev.urlData.get(), ['sort', 'dir'] ));
	    common_dev.urlData.set(data);
	},
	events: {
	    'click': 'toClick',
	    'click a': 'toClick'
	}
    });

    var groupRowView = Backbone.View.extend({
       className: 'group-group',
       tagName: 'li',
       template: _.template( groupRowTpl ),
       initialize: function(param) {

	    var me = this;
	    this.ncollection = param.ncollection;
	    this.nview = new nodeMainView({ model: this.model, ncollection: this.ncollection }).render();

	    this.model.on('change', this.render, this);
	    if( this.model.get('id') == 'default' ) this.$el.addClass('default');
        },
	render: function(){
	    this.nview.$el.detach();
	    this.$el.html(this.template({ model: this.model.toJSON() }));
	    this.$('#nodes').html( this.nview.$el );

	    return this;
	},
        toRemove: function(e){
	  e.stopPropagation();
	  e.preventDefault();

	  if(!confirm('Удалить папку "'+this.model.get('name')+'"')) return;

	  var collection = this.model.collection;

	  //При удалении группы возвращаем устройства в остновную
	  var m = collection.get('default');
	  if( m ) m.set({ nodes: m.get('nodes').concat(this.model.get('nodes')) });

	  //Удаляем модель
	  collection.remove( this.model );
       },
       toRename: function(e){
	  e.stopPropagation();
	  e.preventDefault();

	 var name = prompt("Назавание папки", this.model.get('name'));
	 if(!name) return alert('Название папки не может быть пустым');

 	 this.model.set({ name: name });
       },
	toClick: function(e){
	  e.stopPropagation();
	  e.preventDefault();

	  if( this.model.get('id') == 'default' ) return;
	  common_dev.urlData.set({ node: this.model.get('nodes') });
	},
	toHide: function(e){
	  e.stopPropagation();
	  e.preventDefault();

	  this.model.set({ hide: !this.model.get('hide') });
	},
	events: {
	 'click #hide': 'toHide',
	 'click #remove': 'toRemove',
	 'click #rename': 'toRename',
	 'click': 'toClick'
	}
    });

    var nodeMainView = Backbone.View.extend({
      className: 'device-gn',
      tagName: 'ul',
      initialize: function(param){
	    var me = this;
	    this.ncollection = param.ncollection;

	    function find_node_pos(nodes, id){
		let fk = -1, k = 0;

		_.find(nodes, function(v){
		    if(v == id){
			fk = k;
			return 1;
		    }
		    k++;
		});
		return fk;
	    }

	    this.model.on('change', this.render, this);
	    this.$el.sortable({
		axis: 'y',
		stop: function(e, ui){
		    let entity = ui.item.attr('entity'), p_entity = ui.item.prev().attr('entity');
		    if( entity == p_entity ) return;

		    if(entity != '*') entity = parseInt(entity);
		    if(p_entity != '*') p_entity = parseInt(p_entity);

		    //Находим в какой группе находится нода
		    me.model.collection.find(function(model){
			var nodes = model.get('nodes');

			//Ищем как строку, затем как число
			let k = find_node_pos(nodes, entity);
			if( k != -1 ){

			    //=================Удаляем из старого==================

//			    nodes = []; //Если что то пошло не так и в массиве задублировались элементы можно сбросить
			    nodes = _.without(nodes, entity); //Удаляем ноду из источника
			    model.set({ nodes: nodes }, { silent: true });

			    //=================Добавляем в новый==================
			    let nodes2 = me.model.get('nodes');

			    //Ищем позицию за каким элементом доавлять
			    let k2 = find_node_pos(nodes2, p_entity);

			    //Если изсетсен предущий эелемент
			    k2 = ( k2 == -1 ) ? 0 : k2 + 1;

			    nodes2 = _.without(nodes2, entity); //Если случайно задублировался
			    nodes2.splice(k2, 0, entity);
			    me.model.set({ nodes: nodes2 }, { silent: true });

			    model.trigger('change');
			    me.model.trigger('change');

			    return true;
			}
		    });
		}
	    });
      },
      render: function() {

        if (!this.oldView) this.oldView = {};
        var me = this;

        //Подключаем виевы
	var currView = {},frag = document.createDocumentFragment();
	let nodes = [];

	_.map(this.model.get('nodes'), function(v){ 
	    let m = this.ncollection.get(v) 
	    if( m ) nodes.push(m);
	}, this);

        //Отображение коллекции
	_.forEach(nodes, function(model) {
          var view = this.oldView[model.cid] || new nodeRowView({ model: model }).render();
          currView[model.cid] = view;
          frag.appendChild(view.el);
        }, this);

        //Получаем el
        var el = this.el;

	//Очищаем старое
	el.innerHTML = '';

        //Вставляем новое
        el.appendChild(frag);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );

        this.oldView = currView;
        return this;
      }
    });


    var devMainView = Backbone.View.extend({
      className: 'device-gn',
      tagName: 'ul',
      initialize: function(param){
	    var me = this;
	    this.collection.on('add remove', this.render, this);
      },
      render: function() {

        if (!this.oldView) this.oldView = {};
        var me = this;

        //Подключаем виевы
        var currView = {},
          frag = document.createDocumentFragment();

        //Отображение коллекции
	this.collection.forEach(function(model) {

          var view = this.oldView[model.cid] || new devRowView({ model: model }).render();
          currView[model.cid] = view;
          frag.appendChild(view.el);

        }, this);

        //Получаем el
        var el = this.el;

	//Очищаем старое
	el.innerHTML = '';

        //Вставляем новое
        el.appendChild(frag);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );

        this.oldView = currView;
        return this;
      }
    });

    var groupMainView = Backbone.View.extend({
      template: _.template('<div style="margin:5px 0;"><a id="add" class="btn" title="Добавить папку"><i class="glyphicon glyphicon-plus cursor-pointer"/></a></div><ul></ul>'),
      initialize: function(param) {
        var me = this;

	this.ncollection = param.ncollection;
        this.collection.on('add remove', this.render, this);

	this.$el.html( this.template() );

      },
      render: function() {
        if (!this.oldView) this.oldView = {};
        var me = this;

        //Подключаем виевы
        var currView = {},
          frag = document.createDocumentFragment();

        //Отображение коллекции
	this.collection.forEach(function(model) {
          var view = this.oldView[model.cid] || new groupRowView({ model: model, ncollection: me.ncollection }).render();
          currView[model.cid] = view;
          frag.appendChild(view.el);

        }, this);

        //Получаем el
        var el = this.$('ul').get(0);

	//Очищаем старое
	el.innerHTML = '';

        //Вставляем новое
        el.appendChild(frag);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );

        this.oldView = currView;
        return this;
      },
     toAdd: function(){
	var name = prompt("Назавание папки");
	 if(!name) return alert('Название папки не может быть пустым');

	this.collection.add({ name: name });
     },
     events: {
	     'click #add': 'toAdd'
     }
    });


    return Object.create(visual).extend({
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        me.fmodel = new Backbone.Model();
        me.collection = {
          switch: new collection(null, { url: darsan.makeUrl('', 'device', '/switch'), fmodel: me.fmodel, type: 'switch' }),
          smart: new collection(null, { url: darsan.makeUrl('', 'device', '/smart'), fmodel: me.fmodel, type: 'smart' }),
          pon: new collection(null, { url: darsan.makeUrl('', 'device', '/pon'), fmodel: me.fmodel, type: 'pon' }),
          pontree: new collection(null, { url: darsan.makeUrl('', 'device', '/pontree'), fmodel: me.fmodel, type: 'pontree' })
        };

        //Основной виев
        me.mview = { $el: $('<div>', { html: mainTpl }) };
	me.mview.$nav_el = me.mview.$el.find('#nav');
	me.mview.$mod_el = me.mview.$el.find('#module');

        // SHOW MAC HISTORY
        this.$el.on('keyup', 'input#device-global-mac', function (ev) {
          if (ev.key === 'Enter' || ev.keyCode == 13) {
            common_dev.urlData.set({ node: me.state.node, macClient: ev.target.value || '' });
          }
        });

        this.$el.on('click', '.device-global-mac-submit', function(e) {
          e.preventDefault();
          const macToSearch = document.getElementById('device-global-mac').value;
          common_dev.urlData.set({ node: me.state.node, macClient: macToSearch || '' });
        });

        //Виевы вкладок
        me.view = {
          switch: new mainView({ collection: me.collection.switch }),
          smart: new mainView({ collection: me.collection.smart }),
          pon: new mainView({ collection: me.collection.pon }),
          pontree: new mainView({ collection: me.collection.pontree })
        };

        //Редактируемая таблица Switch
        me.view.switch.$el.html(switchMainTpl);
        new TableAdv({
          view: me.view.switch,
          rows: parseRows({ thead: switchMainTpl, tbody: switchRowTpl }),
          hash: {
            get: function() {
              return (
                userSettings['dynatable-device-switch'] || [
                  { id: 'state', checked: 1 },
                  { id: 'name', checked: 1 },
                  { id: 'uptime', checked: 1 },
                  { id: 'type', checked: 1 },
                  { id: 'ip', checked: 1 },
                  { id: 'control', checked: 1 }
                ]
              );
            },
            set: function(data) {
              darsan
                .putJSON(
                  '',
                  'darsan',
                  '/worker/' + (user.pretend||user.login) + '/config/radix/dynatable-device-switch',
                  data
                )
            }
          }
        });

        //Редактируемая таблица smart
        me.view.smart.$el.html(smartMainTpl);
        new TableAdv({
          view: me.view.smart,
          rows: parseRows({ thead: smartMainTpl, tbody: smartRowTpl }),
          hash: {
            get: function() {
              return (
                userSettings['dynatable-device-smart'] || [
                  { id: 'state', checked: 1 },
                  { id: 'name', checked: 1 },
                  { id: 'uptime', checked: 1 },
                  { id: 'type', checked: 1 },
                  { id: 'ip', checked: 1 },
                  { id: 'control', checked: 1 }
                ]
              );
            },
            set: function(data) {
              darsan
                .putJSON(
                  '',
                  'darsan',
                  '/worker/' + (user.pretend||user.login) + '/config/radix/dynatable-device-smart',
                  data
                )
            }
          }
        });

        //Редактируемая таблица pon
        me.view.pon.$el.html(ponMainTpl);
        new TableAdv({
          view: me.view.pon,
          rows: parseRows({ thead: ponMainTpl, tbody: ponRowTpl }),
          hash: {
            get: function() {
              return (
                userSettings['dynatable-device-pon'] || [
                  { id: 'name_ru', checked: 1 },
                  { id: 'type', checked: 1 },
                  { id: 'state', checked: 1 },
                  { id: 'ip', checked: 1 },
                  { id: 'control', checked: 1 }
                ]
              );
            },
            set: function(data) {
              darsan
                .putJSON(
                  '',
                  'darsan',
                  '/worker/' + (user.pretend||user.login) + '/config/radix/dynatable-device-pon',
                  data
                )
            }
          }
        });


        //Редактируемая таблица pon
        me.view.pontree.$el.html(ponTreeMainTpl);
        new TableAdv({
          view: me.view.pontree,
          rows: parseRows({ thead: ponTreeMainTpl, tbody: ponTreeRowTpl }),
          hash: {
            get: function() {
              return (
                userSettings['dynatable-device-pontree'] || [
                  { id: 'name', checked: 1 },
                  { id: 'node', checked: 1 },
                  { id: 'tree', checked: 1 },
                  { id: 'control', checked: 1 }
                ]
              );
            },
            set: function(data) {
              darsan
                .putJSON(
                  '',
                  'darsan',
                  '/worker/' + (user.pretend||user.login) + '/config/radix/dynatable-device-pontree',
                  data
                )
            }
          }
        });

        //EDIT
        me.edit = {
          switch: editView.extend({ type: 'switch' }),
          smart: editView.extend({ type: 'smart' }),
          pon: editView.extend({ type: 'pon' }),
          pontree: editView.extend({ type: 'pontree' })
        };

        //Радио
        me.pollCh = radio.channel('poller');

        return $.when
          .apply($, [
            darsan.get('', 'device', '/group').then(function(data) {
              advData.group = data;
            }),
            darsan.get('', 'device', '/node').then(function(data) {
              advData.node = data;
            }),
            darsan.get('', 'device', '/meta/device-types').then(function(data) {
              advData.types = data;
            }),
            darsan.get('', 'device', '/meta/device-counts').then(function(data) {
              advData.type_stat = data;
            }),
            darsan.get('', 'device', '/meta/node-stat').then(function(data) {
              advData.node_stat = data;
            }),
            darsan.get('', 'device', '/pon').then(function(data) {
              advData.pon = data;
            }),
          ])
          .done(function() {

            //Форма фильтра
            var $el = $('<form>', { html: _.template(filterTpl)({ advData: advData }) });

            //Обнаружение петель
            if (userSettings.loopDetectEverywhere && userSettings.loopDetectEverywhere != 1) {
              me.pollCh.on('poller.loop-syslog', function(e) {
                if (me.isVisible) common.notifyDeviceLoopDetect(e.text);
              });
            }

            _.map(me.collection, function(coll, key) {

		  //Выставляем занчения пагинации по умолчанию
		  coll.smodel.set({ paged: 1, page: 1, from: 0, count: userSettings.rowsPerPage||50, total: 0 }, {silent: true });

		  //Выставляем занчения пагинации по умолчанию при изменении фильтра
		  coll.fmodel.on('change reset', function(){ 
			coll.smodel.set({ paged: 1, page: 1, from: 0, count: userSettings.rowsPerPage||50, total: 0 }, {silent: true });

			//Загружаем коллекцию
			coll.fetch();
		  });
		  coll.smodel.on('change:page change:sort', function(){ 
			this.set({ from: (this.get('page') - 1) * this.get('count') }, { silent: true });

			//Меняем URL
			var data = _.clone(coll.fmodel.toJSON());
			_.extend(data, _.pick(coll.smodel.toJSON(), ['sort', 'page', 'dir'] ));
			common_dev.urlData.set(data, null, { trigger: false });

			//Загружаем коллекцию
			coll.fetch();
		  });

		  coll.parse = function(request){
			//Общее количество девайсов в списке
			this.smodel.set({ total: request.total }, { silent: true });
			return request.data;
		  };
	    });

            _.map(me.view, function(view, key) {
		 //Количество результатов
		  view.collection.on('sync', function(){
			me.mview.$nav_el.find('a[url="device/' + key + '"] .badge').text(this.smodel.get('total'));
		  });
	    });

	    //Выводим группы
	    var n = advData.node;
	    var  nColl = new nodeColl(n), gColl = new groupColl(), gMain = new groupMainView({ collection: gColl, ncollection: nColl });
	    nColl.comparator = function(m1,m2){
		return new Intl.Collator().compare(m1.get('name'), m2.get('name'));
	    };
	    nColl.sort();
	    nColl.unshift({ entity: '*', name: 'Все' });

	    //Выводим устройства
	    var d = _.map(advData.types, function(v){ return { entity: v.type, name: v.type }});
	    var  dColl = new devColl(d), dMain = new devMainView({ collection: dColl });
	    dColl.comparator = function(m1,m2){
		return new Intl.Collator().compare(m1.get('name'), m2.get('name'));
	    };
	    dColl.sort();
	    dColl.unshift({ entity: '*', name: 'Все' });

	    //Статистика
	    function setFilterStat(){
		var n_total = { up: 0, down: 0, disabled: 0, unknown: 0, total: 0 };

		_.map(advData.node_stat, function(v) {
		    var m = nColl.get(v.entity);
		    if( m ){ 

			var n = {};
			_.map(_.keys(n_total), function(k){
			    n[k] = v[k];
			    n_total[k] += parseInt(v[k]);
			});

			m.set( n );
		    }
		});

		var m = nColl.get('*');
		if( m ) m.set( n_total );

		var d_total = { count: 0 };
		_.map(advData.type_stat, function(v) {
		    var m = dColl.get(v.type);
		    if( m ){ 

			var d = {};
			_.map(_.keys(d_total), function(k){
			    d[k] = v[k];
			    d_total[k] += parseInt(v[k]);
			});

			m.set( d );
		    }
		});

		var m = dColl.get('*');
		if( m ) m.set( d_total );
	    }

            //Обновление счетчиков состояния девайсов
            me.pollCh.on('poller.poll-completed', function(e) {

              //Обновляем счетчики
              $.when
                .apply($, [
                  darsan.get('', 'device', '/meta/device-counts').then(function(data) { advData.type_stat = data; }),
                  darsan.get('', 'device', '/meta/node-stat').then(function(data) { advData.node_stat = data; })
                ]).done(setFilterStat);

	        //Обновляем коллекции
                _.map(me.collection, function(coll){ coll.fetch(); });
            });

	    //======================================Меню поиска с группами устройств=============================================

	    //Статистика нод
	    setFilterStat();

	    //Список всех НОД
	    var nodes = nColl.pluck('entity');

	    //Получаем неиспользованные группы
	    var group = userSettings['findmenu-device-group']||[];

	    //---------------------------------Фильтруем ноды которые были удалены и получаем новые ноды--------------------------------
	    //Ищем ноды, которых нету ни в одной группе
	    let u_nodes = [];
	    _.map(group, function(v){ 

		//Исключаем старые Ноды которые есть в настройках, но были удалены
		let diff = _.difference(v.nodes, nodes);
		_.map(diff, function(v2){v.nodes = _.without(v.nodes, v2);});

		//Добавляем все сипользованые номера нод в массив
		u_nodes = u_nodes.concat(v.nodes); 
	    });

	    //Ищем дефолтную группу, если нет создаем
	    let g = _.findWhere(group, { id: 'default' });
	    if( !g ){
		g = { id: 'default', nodes: [] };
		group.unshift( g );
	    } 

	    //Если есть ноды без группы, доавбяем в дефолтную
	    let diff_nodes = _.difference(nodes, u_nodes);
	    if( !_.isEmpty(diff_nodes)) g.nodes = g.nodes.concat(diff_nodes);

	    gColl.add(group);
	    gColl.on('add remove change', function(){
		darsan.putJSON('', 'darsan', '/worker/' + (user.pretend||user.login) + '/config/radix/findmenu-device-group', gColl.toJSON())
	    });

	    $el.find('#lefttab-group-container').html( gMain.render().el );
	    $el.find('#lefttab-type-container').html( dMain.render().el );

	    //======================================Меню поиска с группами устройств=============================================

            me.mview.$el.find('.filter').html($el);

            //Поиск
            $el.on('submit', function(e) {
              e.preventDefault();

		//Меняем URL
		var data = _.pick(common_dev.urlData.get(), ['sort', 'dir']);
		_.extend(data, me.fmodel.toJSON());

//              var data = me.fmodel.toJSON();

              $el.find('input,select,textarea').each(function(k, v) {
                var e = $(v);
                var name = e.attr('name'),
                  value = e.attr('type') == 'checkbox' ? e.is(':checked') : e.val();
                if (value) {
                  data[name] = value;
                } else {
                  delete data[name];
                }
              });
              common_dev.urlData.set(data);
            });

            //Сброс
            $el.on('reset', function(e) {
              $el.find('input,select,textarea').val('');
              $el.submit();
            });

            //Табы
            me.$el.on('click', '#nav a', function(e) {
              e.preventDefault();

		let url = $(this).attr('url');
		var type = String(url).split('/')[1];

		var data = _.clone(me.fmodel.toJSON());
		_.extend(data, _.pick(me.collection[type].smodel.toJSON(), ['sort', 'page', 'dir'] ));
		common_dev.urlData.set(data, url);
            });

	    //Обновление
	    me.$el.on('click', '.device-global-refresh', function(){
		_.map(me.collection, function(coll){
		    coll.fetch();
		});
	    });

	    //Карта
	    me.$el.on('click', '.device-global-map', function(){

                var query = [];
        	_.map(me.fmodel.toJSON(), function(v, k) {
		    if(k == 'node'){
	              query.push(k + ' IN (' + v + ')');
		    }else if (v.match(/\*/)) {
	        	query.push(k + ' LIKE "' + v + '"');
		    } else {
			query.push(k + '="' + v + '"');
		    }
		});

		 //Закрываем предыдущий
		dialog.close('device-map-window');

		//Модальное окно
		dialog.showModule(mapModule, 
				{ id: 'device-map-window', width: 'calc( 100vw - 40px )', height: window.innerHeight - 100 },
				{ query: query.join(' AND '), url: _.map(me.collection, function(coll, type){ return type }) });
	    });
            me.fmodel.on('all', function(e){
	    });

            //Изменение меню
            me.fmodel.on('change', function() {
                $el.find('input,select,textarea').val('');

                var data = me.fmodel.toJSON();
                _.map(data, function(v, k){ $el.find('[name="' + k + '"]').val(v || ''); });

	        //Индикация нод в меню поиска
	        nColl.map(function(m){ m.unset('selected') });

	        if( data.node ){
		    var array = data.node.split(',');
		    _.map( array, function(v){
			var m = nColl.get(v);
			if( m ) m.set({ selected: data.state||true });
		    })
	        }else{
		    var m = nColl.get('*');
		    if( m ) m.set({ selected: data.state||true });
	        }

	        //Индикация девайсов в меню поиска
	        dColl.map(function(m){ m.unset('selected') });
	        if( data.type ){
		    var array = data.type.split(',');
		    _.map( array, function(v){
			var m = dColl.get(v);
			if( m ) m.set({ selected: 'total' });
		    })
		}else{
		    var m = dColl.get('*');
		    if( m ) m.set({ selected: true });
	        }
            });

            //Свободные порты для свичей
            function setUsedPorts(data) {
              _.map(data, function(v) {
                var model = me.collection.switch.get(v.device);
                if (model) {
                  var portmap = String(model.get('portmap')).split(',');
                  _.map(portmap, function(v2, k2) {
                    portmap[k2] = _.contains(v.used_ports, v2) ? 1 : 0;
                  });

                  model.set({ port_used: portmap.join('') });
                  model.trigger('change');
                }
              });
            }

            darsan.get('', 'client', '/srv/device-utilization').done(function(data) {
              setUsedPorts(data);
              me.collection.switch.on('sync', function() {
                setUsedPorts(data);
              });
            });

          });
      },
      setState: function(state) {
        var me = this;
        me.state = state;

        me._urlPath = me._urlPath || [];
        var _urlPath = state._rest.split('/'),  _urlData = common_dev.urlData.get();

        delete _urlData.macClient;

        //ССылка на девай по id для Урия
        if( _urlPath[1] == 'wired' ) {

            darsan.get('', 'device', '/device/'+_urlPath[2]).done(function(data){
		_urlPath[0] = 'device';
		_urlPath[1] = data.kind;
		Backbone.history.navigate(_urlPath.join('/'), { replace: true, trigger: true });
	    });
	    return;

	 //Ссылка по умолчанию
	}else if (!_.contains(_.keys(this.view), _urlPath[1])) {

          Backbone.history.navigate('/device/switch', { replace: true, trigger: true });
          return;

        }

        //Текущий виев из URL
        var view = me.view[_urlPath[1]], edit = me.edit[_urlPath[1]];

        //===========================================================МАРШРУТИЗАЦИЯ============================================

        //>>>>>>>>>>>>>>>>>>>>>>>>>>>NEW>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        if (_urlPath[2] == '!new') {

          var node = (_.first(advData.node) || {}).entity,
	  type = (_.first(advData.types) || {}).type,
            ipoe_class = (_.first(advData.ipoe_class) || {}).entity;
          var nModel = new model({ node: node, type: type, ipoe_class: ipoe_class });

          var editView = new edit({
            collection: view.collection,
            model: nModel,
            type: type
          }).render();

          nModel.once('sync', function() {
            common_dev.urlData.set(me.fmodel.toJSON(), 'device/' + _urlPath[1], { trigger: true });
          });

          //Показываем виев
          this.$el.children().detach();
          this.$el.html(editView.el);

          //>>>>>>>>>>>>>>>>>>>>>>>>>>>EDIT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        } else if (parseInt(_urlPath[2]) > 0 && _urlPath[3] != 'status') {

	  var editView = new edit({ collection: view.collection });

          //Показываем виев
          this.$el.children().detach();
          this.$el.html(editView.el);

          var nModel = view.collection.get(_urlPath[2]);
          if (nModel) {
            editView.model = nModel;
            editView.render();
          } else {
            nModel = new model({ entity: _urlPath[2] });
            nModel.urlRoot = view.collection.url;
            nModel.fetch({
              success: function() {
                editView.model = nModel;
                editView.render();
              }
            });
          }

          //>>>>>>>>>>>>>>>>>>>>>>>>>>>INDEX>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        } else {

            if (!view) return console.error('Module view "' + _urlPath[1] + '" not found!');

            //Атачим виев, если он не приатачен
            if (!$.contains(this.$el.get(0), this.mview.$el.get(0))) {
		this.$el.children().detach();
		this.$el.html(this.mview.$el);
            }

           //Активизиурем нужную вкладку
            if (!$.contains(this.mview.$el.get(0), view.el)) {
		me.mview.$nav_el.find('a').parents().removeClass('active');
		me.mview.$nav_el.find('a[url="device/' + _urlPath[1] + '"]').parent().addClass('active');
		me.mview.$mod_el.children().detach();
		me.mview.$mod_el.html(view.el);
	    }


          //Действия с коллекицей, при изменении параметров или при переходе на status
          if ((_urlPath[3] != 'status' && !_.isEqual(_urlData, me._urlData)) || (!me._urlData && _urlPath[3] == 'status') ){

	    let n_fdata = _.omit(_urlData, ['dir', 'sort', 'page']), fdata = me.fmodel.toJSON();

	    //Устанавливаем параметры из URL в модель поиска
	    if(!_.isEqual(fdata, n_fdata)){
		me.fmodel.clear({ silent: true });
		me.fmodel.set(n_fdata);
	    }

	    //Если модель поиска пустая, вызываем триггер
	    if(_.isEmpty(me.fmodel.toJSON())) me.fmodel.trigger('change');

	    //Устанавливаем параметры из URL в модель коллекции
	    _.map(me.collection, function(coll,k){
		if( _urlPath[1] == k ) coll.smodel.set(_.pick( _urlData,['dir', 'sort', 'page']));
	    });

            me._urlData = _urlData;
          }
        }

        //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>MAC SEARCH>>>>>>>>>>>>>>>>>>>>>>>>>>.
        if (state.macClient) {
          dialog.close('device-info');

	  var smp = common_dev.size_position_dialog(1);
	  smp.id = 'device-info';
          var d = dialog.showModule(mac_info_module, smp, { mac: state.macClient });
                   d.once('close',function() { 
	    if (new RegExp('device/').test(window.location.pathname)) common_dev.urlData.set(me.fmodel.toJSON(), 'device/' + _urlPath[1], { trigger: false }); 
	  });
        }

        //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>STATUS>>>>>>>>>>>>>>>>>>>>>>>>>>
        if (parseInt(_urlPath[2]) > 0 && _urlPath[3] == 'status') {

	  if( _urlPath[1] == 'pontree' ){
		require(['device/status/modules/pon_tree/module'], function(module){

		  //Модальное окно
		  var smp = common_dev.size_position_dialog(1);
		  smp.id += Date.now();

		  var d = dialog.showModule(Object.create(module), smp, { type: _urlPath[1], tree: _urlPath[2], port: undefined });
		  d.once('close',function(){ 
		    if(new RegExp('device/').test(window.location.pathname)) common_dev.urlData.set(me.fmodel.toJSON(), 'device/' + _urlPath[1], { trigger: false }); 
		  });
		});

	  }else{

		  var smp = common_dev.size_position_dialog();
		  smp.id += Date.now();
		  radio.channel("device:ui").trigger("dialog-opened", true)
		  var d = dialog.showModule(Object.create(statusModule), smp, { type: _urlPath[1], device: _urlPath[2], port: undefined });
		  d.once('close',function()
		  { 
		    radio.channel("device:ui").trigger("dialog-opened", false)
			  if(new RegExp('device/').test(window.location.pathname)) common_dev.urlData.set(me.fmodel.toJSON(), 'device/' + _urlPath[1], { trigger: false }); 
		  });
	  }
        }

        me._urlPath = _urlPath;
      }
    });
  }
);

define([
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'common/dialog/dialog',
    'common/dialog-bs/dialog',
    'navigation',
    'common',
    'device/common',

    'device/status/device-status',

    '_lib/leaflet/leaflet_adv',
    '_lib/leaflet/plugins/leaflet.markercluster',
    '_lib/leaflet/plugins/MarkerCluster.Default.css',
  ],
  function(
    $,
    _,
    Backbone,
    darsan,
    visual,
    dialog,
    dialogBs,
    navigation,
    common,
    common_dev,

    statusModule,
    leaflet
  ){

    //Получаем модули из agruments
    var advData = common_dev.advData;

    return Object.create(visual).extend({
      state: {},
      modules: {},
      devlayer: L.layerGroup(),
      linklayer: L.layerGroup(),
      clusterlayer: L.layerGroup(),
      create: function(el, opt) {
        var me = this;
        this.smodel = new Backbone.Model();

        visual.create.apply(me, arguments);

	var $el = this.$el;
	this.$el.css({ height: window.innerHeight - 150, width: '100%' });

	var lc = leaflet({ center: [0,0], zoom: 10, renderer: L.canvas(), preferCanvas: 1 });
	$el.append(lc.el);

	//Добавляем устройства 
	me.devlayer.addTo(lc.map);

	//Добавляем устройства 
	me.clusterlayer.addTo(lc.map);

	//Добавляем связи
	me.linklayer.addTo(lc.map);

	//Получаем устройства и локации
	this.smodel.on('change', async function(){

	    //Очищаем слои
	    me.devlayer.clearLayers();
	    me.linklayer.clearLayers();
	    me.clusterlayer.clearLayers();

	    var devices = [];
	    function get_devices(){

		var p = [];
		_.map(me.smodel.get('url'), function(type){
		    p.push(darsan.get('', 'device', '/'+type, { query: me.smodel.get('query') }).done(function(data){
			 _.map(data, function(v){ 
			    v.device_type = type;
			    devices.push(v); 
			});
		    }));
		});

		return Promise.all(p);
	    }

	    await get_devices();

	    var locobj = {};
	    _.forEach(devices, function(v){

		if( v.location ){ 
		    if( !locobj[v.location] ) locobj[v.location] = [];
		    locobj[v.location].push(v);
		}

	    });

            darsan.post('', 'geo', '/srv/locations', { locations: _.keys( locobj ).join(',') }).done(function(data){

		var gjson = [], devh = {};
		_.forEach(data, function(v){

		    if( _.isEmpty(v.geo) || _.isEmpty(locobj[v.entity]) ) return;
		    if( v.geo.type != 'Point' ) return;

		    var markers = L.markerClusterGroup();

		    _.map(locobj[v.entity], function(v2){; 
			gjson.push(v.geo);

			var color;
			switch( v2.state ){
			    case 'up':
				color = '#318f3d';
			    break;
			    case 'down':
				color = '#a94442';
			    break;
			    case 'unknown':
				color = '#8a6d3b';
			    break;
			    default:
				color = '#a4a9a8';
			    break;
			}

			var marker = L.circleMarker([v.geo.coordinates[1], v.geo.coordinates[0]], { 
				radius: 10,
				weight: 1,
				fillOpacity: 1,
				opacity: 1,
				fillColor: color, 
				color: color,
				zIndexOffset: 100
			});

			marker.bindTooltip(`<div>`+(v2.name||v2.name_ru)+` (`+v2.type+`)</div><div>Тип: `+v2.device_type+`</div><div>Статус: `+v2.state+` `+((v2.down)?v2.down_from:'')+`</div>`, {direction: 'top' });
			marker.on('click', function(){

			    //Закрываем предыдущий
			    dialog.close('device-status-window');

			    //Модальное окно
			    dialog.showModule(statusModule, 
				{ id: 'device-status-window', width: '1000px',  height: window.innerHeight - 100 },
				{ device: v2.entity, type: v2.device_type, port: undefined });
			});

			devh[v2.entity] = marker;
			markers.addLayer(marker);

		    });
		    me.clusterlayer.addLayer(markers);
		});

		//Центрируем карту
		lc.map.fitBounds( L.geoJSON(gjson).getBounds() );

		//Ищем связи
		darsan.post('', 'device', '/srv/links', { device: _.keys(devh) }).done(function(data){

			var icon = L.icon({
				iconUrl: '/device/map/images/graph.png',
				iconSize: [14, 14],
				iconAnchor: [7, 7],
				title: '222222'
			});

		    _.forEach(data, function(v){

			var marker1 = devh[v.device], marker2 = devh[v.device2];
			if( !marker1 || !marker2 ) return;
			var ltln1 = marker1.getLatLng(), ltln2 = marker2.getLatLng();

			var polyline = L.polyline([[ltln1.lat,ltln1.lng],[ltln2.lat,ltln2.lng]], { 
			    weight: 3,
			    opacity: 0.6,
			});

			me.linklayer.addLayer(polyline);

			var marker = L.marker([ (ltln1.lat + ltln2.lat)/2, (ltln1.lng + ltln2.lng)/2  ], { 
				opacity: 0.6,
				icon: icon
			});
			marker.on('mouseover', function(){
				marker.setOpacity( 1 );
				polyline.setStyle({ opacity: 1 });
			});
			marker.on('mouseout', function(){
				marker.setOpacity( 0.6 );
				polyline.setStyle({ opacity: 0.6 });
			});
			marker.on('click', function(){
			    //Закрываем предыдущий
			    dialog.close('port-load-window');

			    var port, device, type = v.kind;
			    if( v.device && v.port ){ 
				port = v.port;
				device = v.device;
			    }
			    if( v.device2 && v.port2 ){ 
				port = v.port2;
				device = v.device2;
			    }

			    if( !device && !port ) return;

			    //Модальное окно
			  dialogBs.showComponent(
          "device/status/modules/TrafficBoard", "",
  				{ device: device, type: type||'switch', port: port },
	  			{ id: 'port-load-window', width: '1000px',  height: window.innerHeight - 100 },
        )

			})

			me.devlayer.addLayer(marker);
		    });

		    //Делаем маркеры над линиями
		    _.map(devh, function(marker){ marker.bringToFront(); })

		});
	    });

	}, this);

      },
      setState: function(state) {
	var me = this;

	//Устанавливаем состояние
	this.state = state;
	this.smodel.set(state);

	this.$el.css({ height: window.innerHeight - 150, width: '100%' });
	window.dispatchEvent( new Event('resize') );

      },
    });
  }
);

<template>
<div>
    <form class="form-horizontal">
      <Select :options=periodOptions v-model="period" label="Период" :labelSize=5 :size=4 />
    <br/>
    </form>
    <div style="overflow: hidden;text-align: center" class="hcdiv">
      <DeviceGraph type="line" title="Уровни сигнала" yAxisTitle="dBm"
        :series0=onurxDesc :values0=onurx :series1=onutxDesc :values1=onutx :series2=oltrxDesc :values2=oltrx />

      <DeviceGraph type="line" title="Температура" yAxisTitle="Градусы С"
        :series0=tempDesc :values0=temp />
        
      <DoubleGraph type="area" title="Трафик" yAxisTitle="Трафик (бит/c)" 
        :series0=inputDesc :values0=input :series1=outputDesc :values1=output />
      <DoubleGraph type="area" title="Ошибки" yAxisTitle="Ошибки (пак/c)" 
        :series0=inerrorsDesc :values0=inerrors :series1=outerrorsDesc :values1=outerrors />
      <DoubleGraph type="area" title="Сброшенные пакеты" yAxisTitle="Пакеты" 
        :series0=indiscardDesc :values0=indiscard :series1=outdiscardDesc :values1=outdiscard />
      <DoubleGraph type="area" title="Unicast" yAxisTitle="Пакеты" 
        :series0=inucastDesc :values0=inucast :series1=outucastDesc :values1=outucast />
      <DoubleGraph type="area" title="Multicast" yAxisTitle="Пакеты" 
        :series0=inmucastDesc :values0=inmucast :series1=outmucastDesc :values1=outmucast />
      <DoubleGraph type="area" title="Broadcast" yAxisTitle="Пакеты" 
        :series0=inbrcastDesc :values0=inbrcast :series1=outbrcastDesc :values1=outbrcast />
    </div>
</div>
</template>

<script>
import Select from "common/visual/input/Select.vue"
import DeviceGraph from "device/status/modules/DeviceGraph.vue"
import DoubleGraph from "device/status/modules/DoubleGraph.vue"
import darsan from "darsan"

export default {

  name: "OnuGraph",

  components: {Select, DoubleGraph, DeviceGraph},

  data()
  {
    return {
      state: {},
      
      period: "day2",
      
      periodOptions:  [
        { value: 'hour6', text: '6 часов' },
        { value: 'hour12', text: '12 часов' },
        { value: 'day', text: 'Сутки' },
        { value: 'day2', text: 'Двое суток' },
        { value: 'week', text: 'Неделя' },
        { value: 'month', text: 'Месяц' },
      ],
      
      portTags: {},
      
      inputDesc: {
         name: "Входящий",
         color: "green",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " бит/c",
         },
       },
       
      outputDesc: {
         name: "Исходящий",
         color: "blue",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " бит/c",
         },
       },

      inerrorsDesc: {
         name: "Ошибки RX",
         color: "orange",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " пак/c",
         },
       },
       
      outerrorsDesc: {
         name: "Ошибки TX",
         color: "red",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: " пак/c",
         },
       },

      indiscardDesc: {
         name: "Входящие",
         color: "mediumpurple",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outdiscardDesc: {
         name: "Исходящие",
         color: "goldenrod",
         tooltip: {
           valueDecimals: 0,
         },
       },

      inucastDesc: {
         name: "Входящие",
         color: "mediumpurple",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outucastDesc: {
         name: "Исходящие",
         color: "goldenrod",
         tooltip: {
           valueDecimals: 0,
         },
       },

      inmucastDesc: {
         name: "Входящие",
         color: "khaki",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outmucastDesc: {
         name: "Исходящие",
         color: "firebrick",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
       inbrcastDesc: {
         name: "Входящие",
         color: "darkgreen",
         tooltip: {
           valueDecimals: 0,
         },
       },
       
      outbrcastDesc: {
         name: "Исходящие",
         color: "darkcyan",
         tooltip: {
           valueDecimals: 0,
         },
       },

      onutxDesc: {
         name: "ONU TX",
         color: "green",
         tooltip: {
           valueDecimals: 1,
         },
       },

      onurxDesc: {
         name: "ONU RX",
         color: "blue",
         tooltip: {
           valueDecimals: 1,
         },
       },

      oltrxDesc: {
         name: "OLT RX",
         color: "firebrick",
         tooltip: {
           valueDecimals: 1,
         },
       },
       
       tempDesc: {
         name: "Градусы",
         color: "purple",
         tooltip: {
           valueDecimals: 0,
           valueSuffix: "°",
         },

       },

      input: { start: 0, step: 0, data: [] },
      output: { start: 0, step: 0, data: [] },
      inerrors: { start: 0, step: 0, data: [] },
      outerrors: { start: 0, step: 0, data: [] },
      indiscard: { start: 0, step: 0, data: [] },
      outdiscard: { start: 0, step: 0, data: [] },
      inbrcast: { start: 0, step: 0, data: [] },
      outbrcast: { start: 0, step: 0, data: [] },
      inucast: { start: 0, step: 0, data: [] },
      outucast: { start: 0, step: 0, data: [] },
      inmucast: { start: 0, step: 0, data: [] },
      outmucast: { start: 0, step: 0, data: [] },

      onurx: { start: 0, step: 0, data: [] },
      onutx: { start: 0, step: 0, data: [] },
      oltrx: { start: 0, step: 0, data: [] },
      temp: { start: 0, step: 0, data: [] },
    }
  },
  
  methods: {
    async loadValues(period)
    {
      const data = await darsan.get(this.state.prefix, "device", `/pon/${this.state.device}/onu/${this.state.mac}/data/general?period=${this.period}`)
      
      this.onurx.start = this.onutx.start = this.oltrx.start = this.temp.start = data.start
      this.onurx.step = this.onutx.step = this.oltrx.step = this.temp.step = data.step
      
      this.onutx.data = data.series.onu_tx
      this.onurx.data = data.series.onu_rx
      this.oltrx.data = data.series.olt_rx
      this.temp.data = data.series.temperature
      
      const trafdata = await darsan.get(this.state.prefix, "device", `/pon/${this.state.device}/onu/${this.state.mac}/data/traffic?period=${this.period}`)
      
      this.input.start = this.output.start = this.inerrors.start = this.outerrors.start = this.indiscard.start = this.outdiscard.start = trafdata.start
      this.input.step = this.output.step = this.inerrors.step = this.outerrors.step = this.indiscard.step = this.outdiscard.step = trafdata.step
      this.inbrcast.start = this.outbrcast.start = this.inucast.start = this.outucast.start = this.inmucast.start = this.outmucast.start = trafdata.start
      this.inbrcast.step = this.outbrcast.step = this.inucast.step = this.outucast.step = this.inmucast.step = this.outmucast.step = trafdata.step
      
      this.input.data = trafdata.series.input
      this.output.data = trafdata.series.output
      this.inerrors.data = trafdata.series.inerrors
      this.outerrors.data = trafdata.series.outerrors
      this.indiscard.data = trafdata.series.indiscard
      this.outdiscard.data = trafdata.series.outdiscard

      this.inbrcast.data = trafdata.series.inbrcast
      this.outbrcast.data = trafdata.series.outbrcast
      this.inucast.data = trafdata.series.inucast
      this.outucast.data = trafdata.series.outucast
      this.inmucast.data = trafdata.series.inmucast
      this.outmucast.data = trafdata.series.outmucast
    }
  },
  
  watch: {
    state:
    {
      handler: async function(val) 
      { 
        this.loadValues() 
      },
    },
    period:
    {
      handler: function(val) { this.loadValues() },
      immediate: false,
    },
  },

}
</script>

<style>
.hcdiv .highcharts-container {
  display: inline-block !important;
}
</style>


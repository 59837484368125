define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'navigation',
    'common',
    'text-loader!device/svlan-cvlan-editor/row.tpl'
  ],
  function($, _, Backbone, darsan, visual, navigation, common, rowTpl) {
    var model = Backbone.Model.extend({
      idAttribute: 'entity',
      url: function(){ 
	    var urlRoot = (this.collection) ? this.collection.url : this.urlRoot;
	    return urlRoot + '/' + this.get('svlan'); 
      },
      defaults: function(){ return { svlan: '', cvlan_ranges: '' }},
      initialize: function() {
        this.on('error', function(a, e) {
          common.notifyError(e.responseText);
        });
      }
    });

    var collection = Backbone.Collection.extend({
      model: model
    });

    var addView = Backbone.View.extend({
      tagName: 'form',
      template: _.template(rowTpl),
      render: function() {
        this.$el.html(this.template({ model: this.model.toJSON() }));
        return this;
      },
      toAdd: function(e) {
        e.preventDefault();
        e.stopPropagation();

        var model = this.model;
        var collection = this.collection;

	model.urlRoot = collection.url;
	model.save(null,{
	    success: function(){
		collection.fetch({ reset: true });
		common.notify("SVLAN "+model.get('svlan')+" добавлен");
            },
	});
      },
      toChange: function(e) {
        e.stopPropagation();
        var e = $(e.currentTarget),
          name = e.attr('name'),
          value = e.attr('type') == 'checkbox' ? +e.is(':checked') : e.val();
        this.model.set(name, value);
      },
      events: {
        'click button#add': 'toAdd',
        'change input,select,textarea': 'toChange'
      }
    });

    var rowView = Backbone.View.extend({
      tagName: 'form',
      template: _.template(rowTpl),
      render: function() {
        this.$el.html(this.template({ model: this.model.toJSON() }));
        return this;
      },
      toRemove: function(e) {
        e.preventDefault();
        e.stopPropagation();
        var model = this.model;
        if (!confirm('Удалить SVLAN ' + model.get('svlan') + '?')) return;
        model.destroy({
	    wait: true,
	    success: function() {
		common.notify("SVLAN "+model.get('svlan')+" удален");
            }
	});
      },
      toChange: function(e) {
	  e.stopPropagation();
	  var me = this;

          var model = this.model;
          var e = $(e.currentTarget);

	  var data = {};
	  data[e.attr('name')] = e.attr('type') == 'checkbox' ? +e.is(':checked') : e.val();
	  this.model.save(data, { patch: true, 
	    success: function() {
		me.animateSuccess(e);
	    },
	    error: function(){
		me.animateFail(e);
	    }
	  });
      },
      animate: function(el, _class) {
        el
          .removeClass(_class)
          .addClass(_class)
          .delay(1000)
          .queue(function() {
            $(this)
              .removeClass(_class)
              .dequeue();
          });
      },
      animateSuccess: function(el) {
        return this.animate(el, 'animate_success');
      },
      animateFail: function(el) {
        return this.animate(el, 'animate_fail');
      },

      events: {
        'click button#remove': 'toRemove',
        'change input,select,textarea': 'toChange'
      }
    });

    var mainView = Backbone.View.extend({
      initialize: function() {
        this.collection.on('remove add reset', this.render, this);
      },
      render: function(param) {
        param = param || {};
        if (!this.oldView) this.oldView = {};

        //Подключаем виевы
        var currView = {},
          frag = document.createDocumentFragment();
        this.collection.forEach(function(model) {
          var view;
          if (param.force || !this.oldView[model.id]) {
            view = new rowView({ model: model, collection: this.collection }).render();
          } else {
            view = this.oldView[model.id];
          }

          currView[model.cid] = view;
          frag.appendChild(view.el);
        }, this);

        //Меню добавления
        var aview = new addView({ model: new model(), collection: this.collection }).render();
        currView[model.id] = aview;
        frag.appendChild(aview.el);

        //Вставляем контент
        this.$el.html(frag);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );

        this.oldView = currView;
        return this;
      }
    });

    return Object.create(visual).extend({
      parent: null,
      name: 'attrEditor',
      state: {},
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        me.coll = new collection();
        me.view = new mainView({ collection: me.coll });
        //			me.$el.html( me.view.el );
      },
      setState: function(state) {
        var me = this;
        me.state = me.state || {};
        var url = state.url;

        if (me.state.url == url) return;
        me.state = state;

        me.coll.url = url;
        me.coll.fetch({ reset: true });
        me.$el.html(me.view.el);
      }
    });
  }
);

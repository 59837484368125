define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',

    'common/visual/visual',
    'common/dialog/dialog',
    'device/common',
    'common',

    'text-loader!device/status/modules/cable_diag/row.tpl',
    'text-loader!device/status/modules/cable_diag/table.tpl'
  ],
  function($, _, Backbone, darsan, visual, dialog, common_dev, common, rowTemplate, tableTemplate) {

    var prefix;
    var advData;
    var lData = {
      pairStatus: [
        { id: 0, name: 'OK' },
        { id: 1, name: 'Open' },
        { id: 2, name: 'Short' },
        { id: 3, name: 'Open Short' },
        { id: 4, name: 'Crosstalk' },
        { id: 5, name: 'Unknown' },
        { id: 6, name: 'Count' },
        { id: 7, name: 'No cable' },
        { id: 8, name: 'Other' }
      ],
      linkStatus: [{ id: 0, name: 'Down' }, { id: 1, name: 'Up' }, { id: 2, name: 'Other' }]
    };

    var model = Backbone.Model.extend({});

    var collection = Backbone.Collection.extend({
      model: model,
      initialize: function(array, param) {
        this.smodel = param.smodel;
      }
    });

    var rowView = Backbone.View.extend({
      tagName: 'tr',
      template: _.template(rowTemplate),
      initialize: function() {
        this.listenTo(this.model, 'change', this.render);
      },
      render: function() {
        this.$el.html(
          this.template({
            model: this.model.toJSON(),
            advData: advData,
            common: common,
            lData: lData
          })
        );
        return this;
      }
    });

    var mainView = Backbone.View.extend({
      initialize: function(param) {
        var me = this;
        this.$el.html(tableTemplate);
        this.collection.on('sync reset', me.render, this);

        var smodel = me.collection.smodel;
        smodel.on('change:sort', function() {
          me.$('.sort').removeClass('sort_up sort_down');
          me
            .$('.sort[attr=' + smodel.get('sort') + ']')
            .addClass(smodel.get('dir') == 1 ? 'sort_up' : 'sort_down');
        });
      },
      render: function() {
        if (!this.oldView) this.oldView = {};
        _.each(
          this.oldView,
          function(v) {
            v.$el.detach();
          },
          this
        );

        var me = this;

        //Подключаем виевы
        var currView = {},
          array = [];

        //Отображение коллекции
        var param = this.collection._param;
        this.collection.forEach(function(model) {
          var view = this.oldView[model.cid] || new rowView({ model: model }).render();
          currView[model.cid] = view;
          array.push(view.el);
        }, this);

        //Вставляем контент
        this.$('tbody').html(array);

        //Уничтожаем неиспользуемые
        _.each(
          this.oldView,
          function(v, k) {
            if (!currView[k]) v.remove();
          },
          this
        );
        this.oldView = currView;

        return this;
      },
      toSort: function(e) {
        e.preventDefault();

        var target = e.currentTarget;
        var attr = target.getAttribute('attr'),
          dir = target.getAttribute('dir');
        var smodel = this.collection.smodel;

        switch (dir) {
          case '1':
            dir = 0;
            break;
          case '0':
            dir = undefined;
            break;
          default:
            dir = 1;
            break;
        }

        if (dir == undefined) {
          target.removeAttribute('dir');
          smodel.unset('sort', { silent: true });
          smodel.unset('dir', { silent: true }).trigger('change:sort');
        } else {
          target.setAttribute('dir', dir);
          smodel.set({ sort: attr, dir: dir }, { silent: true }).trigger('change:sort');
        }
      },
      events: {
        'click .sort': 'toSort'
      }
    });

    return Object.create(visual).extend({
      title: 'Диагностика',
      name: 'devicePortDiag',
      icon: 'transfer',
      deferred: [],
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        this.smodel = new Backbone.Model();
        this.collection = new collection(null, { smodel: this.smodel });

        this.view = new mainView({ collection: this.collection });
        this.$el.html(this.view.el);

	//Устраняем мигание/Очищаем коллекцию
	this.smodel.on('all', function(v) {
          if ( v == 'change:device' ) me.collection.reset();
	});

        this.smodel.on('change:device change:port change:sort', function() {

	  //Отменяем предыдущие запросы
	  _.map(me.deferred, function(v){ v.fail() });
	  me.deferred = [];


          if (!me.smodel.get('device') ||!me.smodel.get('port')){
            me.collection.reset();
            return;
          }

          //Получаем fdb со всех портов
           var a = [];
          _.forEach(String(me.smodel.get('port')).split(','), function(v) {
            me.deferred.push(darsan.get(prefix,'device','/device/' + me.smodel.get('device') + '/port/' + v + '/cable-diag').done(function(b) {
        	  if (!_.isEmpty(b)) a.push(b);
              }));
          });

          //Обьединяем результаты и находим сесии и пользователей
          $.when.apply($, me.deferred).done(function() {
            me.collection.reset(
              _.sortBy(a, function(v) {
                return parseInt(v.port);
              })
            );
          });
        });
      },
      setState: function(state) {
        var me = this;
        me.state = state;
	prefix = state.prefix||config.domain;

        delete me.state.tabs;
        this.smodel.set(me.state);
      }
    });
  }
);

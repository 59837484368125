var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      { staticClass: "form-horizontal" },
      [
        _c("Select", {
          attrs: {
            options: _vm.periodOptions,
            label: "Период",
            labelSize: 5,
            size: 3
          },
          model: {
            value: _vm.period,
            callback: function($$v) {
              _vm.period = $$v
            },
            expression: "period"
          }
        }),
        _vm._v(" "),
        _c("br")
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "hcdiv",
        staticStyle: { overflow: "hidden", "text-align": "center" }
      },
      [
        _c("DeviceGraph", {
          attrs: {
            type: "area",
            title: "Загрузка ЦП",
            yAxisTitle: "%",
            series0: _vm.series0,
            values0: _vm.cpu
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
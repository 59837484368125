define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'navigation',
    'common',
    'device/common',
    'text-loader!device/status/modules/mac_info/mainTpl.tpl'
  ], function($, _, Backbone, darsan, visual, navigation, common, dev_common, mainTpl) {

    var prefix;
    var cache = {};

    return Object.create(visual).extend({
      title: 'MAC Info',
      name: 'deviceInfo',
      icon: 'hdd',
      template: _.template(mainTpl),

      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);
      },

      setState: function(state) {
        var me = this;
        me.$el.empty();

	if(prefix != state.prefix||config.domain) cache = {};

        me.state = state;
	prefix = state.prefix||config.domain;
	

	      me.title = "Информация о MAC " + decodeURIComponent(me.state.mac);

	      var obj = {};
	      var d = [];

	      d.push(darsan.get(prefix, 'device', '/mac/' + me.state.mac + '/seen')
	      .done(function (data) {
				  obj.current = [];
				  obj.last_session = [];
				  obj.last_seen = [];

				  _.map(data.current, function(v) {
						v.type = v.kind;
						obj.current.push(v);
	  			});

				  if (data.last_session) {
				    data.last_session.type = data.last_session.kind;
				    obj.last_session.push(data.last_session);
				  }

				  if (data.last_seen) {
				    data.last_seen.type = data.last_seen.kind;
				    obj.last_seen.push(data.last_seen);
				  }
				}));

				d.push(darsan.get(prefix, 'device', '/mac/' + me.state.mac + '/history').done(function (data) {
				  obj.history = [];

				  _.map(data, function (v) {
						v.type = v.kind;
						obj.history.push(v);
				  });
				}));

        $.when.apply($, d).always(function() {
			    function getDevice(val) {
						_.defaults(val, { type:  'switch' });

						var promise = cache[val.type + ':' + val.device];

						if (promise) {
					    promise.done(function (data) {
								_.extend(val, data);
					    });
						} else {
				    	var promise = darsan.get(prefix, 'device', '/' + val.type + '/' + (val.device || val.pon)).then(function (data) {
								return { device_name: data.name, device_type: data.type };
				    	});

				    	promise.done(function (data) {
								_.extend(val, data);
				    	});

				    	cache[val.type + ':' + val.device] = promise;
						}
			    }

			    _.map(obj.current, getDevice);
			    _.map(obj.last_seen, getDevice);
			    _.map(obj.last_session, getDevice);
			    _.map(obj.history, getDevice);

		      $.when.apply($, _.toArray( cache )).always(function() {
		        me.$el.html( me.template({ data: obj, common: common }));
			  });

		    });
			}
    });
  }
);

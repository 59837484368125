define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'common/context/context',
    'common/dialog/dialog',
    'navigation',
    'permission',
    'common',
    'location-input',
    'device/common',
    'common/popover/popover',
    'common/visual/vue-module',

    'device/status/statusCss.css',
    'text-loader!device/status/statusTpl.tpl',
    'text-loader!device/status/scriptTpl.tpl',
    'text-loader!device/status/portTpl.tpl',
    'text-loader!device/status/bindTpl.tpl',
    'text-loader!device/status/printTpl.tpl',

    'device/status/modules/mac/module',
    'device/status/modules/mac_old/module',
    'device/status/modules/cable_diag/module',
    'device/status/modules/pon_tree/module',
    
    "device/status/modules/GeneralBoard.vue",
    "device/status/modules/TrafficBoard.vue",
  ],
  function(
    $,
    _,
    Backbone,
    darsan,
    visual,
    context,
    dialog,
    navigation,
    permission,
    common,
    locInput,
    common_dev,
    popover,
    vuemod,
    statusCss,
    statusTpl,
    scriptTpl,
    portTpl,
    bindTpl,
    printTpl,
    module1,
    module2,
    module6,
    module8,
    
    genGraphVue,
    trafGraphVue,
  ) {

    var prefix;
    
    const deviceGraph = Object.create(vuemod).extend({
      name: "deviceGraph",
      title: 'Графики (Общие)',
      icon: 'stats',
      vue: genGraphVue,
    })

    const trafficGraph = Object.create(vuemod).extend({
      name: "devicePortGraph",
      title: 'Графики по порту',
      icon: 'stats',
      vue: trafGraphVue,
    })

    //Получаем модули из agruments
    var modules = _.filter(arguments, function(v){ return  _.isObject(v) && !_.isEmpty(v.name) })
    modules.push(trafficGraph)
    modules.push(deviceGraph)
    var advData = common_dev.advData;

    return Object.create(visual).extend({
      state: {},
      modules: {},
      template: _.template(statusTpl),
      setPortsActive: function(){
	    var me = this;
	    me.$el.find('div.port-group').removeClass('active');
	    _.map(String(me.smodel.get('port')).split(','), function(v){
    		me.$el.find('div.port-group[attr="'+v+'"]').addClass('active');
	    });
      },
      setSubModuleSize: function(){
	    var me = this;
/*
	   var t = me.$el.closest('.ui-dialog').innerHeight(), b = me.$el.find('.device-status').outerHeight() + 300;
	    me.$el.find('#module').height(t - b);
*/
      },
      
      setTabs: function( param )
      {
        var $nel = this.$el.find('ul#nav');
        $nel.empty();

        this.modules = {};
        _.each(param, function(v) {

          var mod = _.findWhere(modules, { name: v });
	        if (!mod) return console.error('Module "' + v + '" not found!');

  	      //Клонируем модуль
	        mod = Object.create(mod);
          $nel.append('<li><a url="' + v + '" style="cursor:pointer;"><span class="glyphicon glyphicon-' + mod.icon + '"></span>&nbsp;<span class="tab-name">' +mod.title + '</span></a></li>');
          mod.create($('<div>'), { advData: advData });
          this.modules[v] = mod;
        },this);
      },
      
      create: function(el, opt) {
        var me = this;
        this.smodel = new Backbone.Model();

        visual.create.apply(me, arguments);

        this.$el.html('<div id="device-status"></div><div id="device-status-tabs" class="device-status-tabs"><ul class="nav nav-tabs" id="nav"></ul><br><div id="module"></div></div>');
        this.model = new Backbone.Model();

        var $sel = this.$el.find('#device-status'), $nel = this.$el.find('#device-status-tabs');
        $nel.on('click', '#nav a', function(e) {
	    e.preventDefault();
	    var tab = $(e.currentTarget).attr('url');

	   //Меняем состояние (URL);
	   common_dev.urlData2.set({ tab: tab });

	   //Меняем состояние модуля
	   me.setState(_.extend( _.clone(me.state), { tab: tab }));
        });

        $sel.on('click', '.showHideOldMacs', ev => {
          var oldMacsCells = this.$el.find('.port-mac-old');
          oldMacsCells.each((i, el) => $(el).toggleClass('hidden'));
        });

	let timeout, portT = _.template(portTpl);
	$sel.on('mouseover','.port-info',function(e){
		clearTimeout( timeout );
		timeout = setTimeout(function(){
		    let model = me.model.toJSON();
		    let num = $(e.currentTarget).closest('.port-group').attr('attr'), ports = String(model.ports_on).split(''), unt_ports = String(model.untagged_ports).split(''), adm_ports = String(model.ports_adm_on).split('');
		    darsan.get(prefix, 'device','/'+me.smodel.get('type') +'/' +me.smodel.get('device') +'/port/' + num +'/tag').then(function(d){
			let k = num - 1;
			let mac = (me.model.current_macs_count||{})[num],bind_uid = (model.bind_uid||{})[num],loop = (model.ports_loop||{})[num];
			if( mac == 0 ) mac = undefined;

			popover.show(e, { placement: 'top', content: portT({ model: { tag: d.tag, number: num, on: ports[k], adm_on: adm_ports[k], untagged: unt_ports[k], mac: mac, bind_uid: bind_uid, loop: loop }, common: common }) });
		    });

		}, 100);
	}).on('mouseleave','.port-info',function(e){
		clearTimeout(timeout);
		popover.close();
	});

	let bindT = _.template(bindTpl);
	$sel.on('mouseover','.bind-client',function(e){
		clearTimeout( timeout );
		timeout = setTimeout(function(){
		    let model = me.model.toJSON();
		    let num = $(e.currentTarget).closest('.port-group').attr('attr');

		    popover.show(e, { placement: 'top', content: bindT({ model: { bind_uid: (model.bind_uid||{})[num] }, common: common }) });
		}, 100);
	}).on('mouseleave','.bind-client',function(e){
		clearTimeout(timeout);
		popover.close();
	});

	$sel.on('click', '.showPassword', ev => {
			var device = me.smodel.get('device');
			var type   = me.smodel.get('type');

			darsan.get(prefix, 'device', '/' + type + '/' + device + '/password')
			.done((data) => {
				alert(data.password);
			});
		});

	//Выбираем все порты
        $sel.on('click', '.showAllMacs', ev => {
          var ports = {}, ports_a = {};
	    
	  this.$el.find('div.port').each(function(k,v){
		var $el = $(v).closest('.port-group'), id = $el.attr('attr');

		ports[id] = id;
		if($el.hasClass('active')) ports_a[id] = id;
	  });

	  var port = {};
	  if( _.size(ports) > _.size(ports_a)) port = ports;

	  //Меняем состояние (URL);
	  common_dev.urlData2.set({ port: _.keys(port).join(',') });

	  //Меняем состояние модуля
	  me.setState(_.extend( _.clone(me.state), { port: _.keys(port).join(',') }));
        });

	//Выбираем 1 порт
        $sel.on('click', 'div.port', function(e){

          var $el = $(e.currentTarget).closest('.port-group'), id = $el.attr('attr');
          var port = ( me.state.port ) ? String(me.state.port).split(',') : [];

	  if (e.ctrlKey) {
		if( _.contains(port, id)){
		    port = _.without(port, id);
		}else{
		    port.push(id);
		}
	  } else {
		port = [ id ];
	  }

	  //Меняем состояние (URL);
	  common_dev.urlData2.set({ port: (( !port ) ? undefined :  port.join(',')) }, {});

	  //Меняем состояние модуля
	  me.setState(_.extend( _.clone(me.state), { port: port.join(',') }));

	});


	//Команды устройства
	  function toHex(str) {
	    var result = '';
	    for (var i=0; i<str.length; i++) {
	      result += str.charCodeAt(i).toString(16);
	    }
	    return result;
	 }

        $sel.on('click', '.device-script', function(e) {

		e.preventDefault();
		darsan.get(prefix, 'device', '/meta/script').done(function(data){

		    var token = localStorage.getItem('radix-token');


		    var array = []
		    _.forEach(data, function(v){

//			if(!new RegExp(v.device_type).test(me.model.get('type'))) return;
			if(!v.title) return;

			array.push({ name: v.title, func: function(){

			    if (me.ws) {
				me.ws.close();
		                delete me.ws;
			    }

    			  //Закрываем предыдущий
		          dialog.close('device-script-window');
			    var $el = $('<div>', { html: scriptTpl });

			    //Модальное окно
			    var d = dialog.show('', $el, { id: 'device-script-window', width: '1000px', height: 'auto' });
			    var params = { device_id: me.model.get('entity') };

			    function print( v ){

				if( v.style  == 'error' ) return `<span style="color:red">`+v.t+`</span>`;
				return v.t;
			    }

			    function withParams( v ){

				var $fel = $('<form>');
				_.forEach(v.params, function(v2){
				    v2.default = ( 'default' in v2 ) ? v2.default : '';


				    var input = "<input name='"+v2.name+"' class='form-control' value='"+v2.default+"'>";
				    if( v2.type == 'integer' ) input = "<input name='"+v2.name+"' type='number' class='form-control' value='"+v2.default+"' min='"+v2.min+"' max='"+v2.max+"'>";
				    if( v2.type == 'oneof' ) input = "<select name='"+v2.name+"' class='form-control' value='"+v2.default+"'>"+_.map(v2.options, function(v){ return '<option value="'+v.value+'">'+v.name+'</option>' })+"</select>";

				    $fel.append("<span>"+(v2.name_ru||'')+"&nbsp;"+input+"</span>&nbsp;&nbsp;");
				});

				$fel.append("<button type='submit' class='btn btn-default'>Отправить</button>");
				$fel.on('submit', function(e){
				    e.preventDefault();

				    $fel.find('input,select,textarea').each(function(k, v) {
					var e = $(v);
					params[e.attr('name')] = e.attr('type') == 'checkbox' ? e.is(':checked') : e.val();
				    });
				    $fel.remove();

		        	    me.ws = new WebSocket( darsan.makeUrl(prefix, 'device', '/script/'+v.name+'?'+_.map(params, function(v,k){ return k + '=' + v }).join('&')).replace(/^http/, 'ws'), 'Darsan2-'+toHex(token));
		        	    me.ws.onclose = function(e) {
					if( e.code != 1000 ) $el.find('.out').append(print({ t: e.reason, style: 'error' })).scrollTop(1000000);
				    };
				    me.ws.onmessage = function(e) { 

					var data_ = JSON.parse(e.data);
					$el.find('.out').append(print(data_)).scrollTop(1000000);

					if( data_.command == 'ask-and-run' ){

					    me.ws.close();

					    var v = _.findWhere(data, {name:data_.script});
					    if( _.isEmpty(v)) return;

					    v.params = new Array().concat(v.params, data_.params);

					    if( !_.isEmpty(v.params)){
						withParams(v);
					    }else{
						withoutParams(v);
					    }
					}
				    };
				});

				$el.find('.out').append($fel);
			    }

			    function withoutParams( v ){

				me.ws = new WebSocket( darsan.makeUrl(prefix, 'device', '/script/'+v.name+'?'+_.map(params, function(v,k){ return k + '=' + v }).join('&')).replace(/^http/, 'ws'), 'Darsan2-'+toHex(token));
				me.ws.onclose = function(e) {
					if( e.code != 1000 ) $el.find('.out').append(print({ t: e.reason, style: 'error' })).scrollTop(1000000);
				};
				me.ws.onmessage = function(e) { 

				    var data_ = JSON.parse(e.data);
				    $el.find('.out').append(print(data_)).scrollTop(1000000);

				    if( data_.command == 'ask-and-run' ){

					me.ws.close();

					var v = _.findWhere(data, {name:data_.script});
					if( _.isEmpty(v)) return;

					v.params = new Array().concat(v.params, data_.params);

					if( !_.isEmpty(v.params)){
						withParams(v);
					}else{
						withoutParams(v);
					}
				    }
				};

			    }

			    if( !_.isEmpty(v.params)){

				withParams(v);

			    }else{

				withoutParams(v);

			    }

			    //Разрываем соединение при закрытии окна
			    d.dialog({
				close: function(){
				    if (me.ws) {
					me.ws.close();
		            		delete me.ws;
				    }
			        }
			    });

			}});
		    });


		    array.push({ name: 'Порты (для печати)', func: function(){

			me.deferred = [];
			let out = {}, ipoe = [];

			let a = [], o = [], t = [];

			//Получаем IPoE
			me.deferred.push( darsan.get(prefix, 'client', '/srv/device/' + me.smodel.get('device')+'/clients').done(function(b){
			    ipoe = b;
			}));

    			//Получаем таги
			_.forEach(String(me.model.get('portmap')).split(','), function(v) {

			//Получаем fdb со всех портов
		            me.deferred.push( darsan.get(prefix, 'device','/' + me.smodel.get('type') + '/' + me.smodel.get('device') + '/port/' + v + '/fdb').done(function(b) {
            			  Array.prototype.push.apply(a, b);
        		    }));


			//Получаем старые маки со всех портов
		            me.deferred.push( darsan.get(prefix, 'device','/' + me.smodel.get('type') + '/' + me.smodel.get('device') + '/port/' + v + '/oldmacs').done(function(b) {
            			  Array.prototype.push.apply(o, b);
        		    }));

			//Получаем таги
		            me.deferred.push( darsan.get(prefix, 'device','/' + me.smodel.get('type') + '/' + me.smodel.get('device') + '/port/' + v + '/tag').done(function(b) {
				  t.push(b);
        		    }));
        		});


        		$.when.apply($, me.deferred).done(function() {

			    let ports_on = me.model.get('ports_on').split(''), ports_adm_on = me.model.get('ports_adm_on').split(''), untagged_ports = me.model.get('untagged_ports').split('') ;
			    let m = {};

			    _.map(a, function(v){ v.port = parseInt(v.port) });
			    _.map(o, function(v){ v.port = parseInt(v.port) });
			    _.map(t, function(v){ v.port = parseInt(v.port) });

			    _.forEach(String(me.model.get('portmap')).split(','), function(v,k){

				let ma = _.where(a, { port: parseInt(v) }), mo = _.where(o, { port: parseInt(v) });
				out[k] = { port: v, 
					   tag: (_.findWhere(t, { port: parseInt(v) })||{}).tag,
					   adm_status: parseInt(ports_adm_on[k]), 
					   status: parseInt( ports_on[k] ), 
					   untagged: parseInt( untagged_ports[k] ), 
					   users: [],
					   macs: ma, 
					   old_macs: mo,
					   vlan: _.map(_.where(a, { port: parseInt(v) }), function(v){ return parseInt(v.vlan) }) };

				_.map(mo, function(v){ m[v.mac] = out[k] });
				_.map(ma, function(v){ m[v.mac] = out[k] });
			    });

			    //Ищем пользователей по макам
			    darsan.post(prefix, 'client', '/clients-from-macs', { macs: _.keys(m).join(',').replace(/:/g, '') }).always(function(data){
				_.map(data, function(v){
				    if( m[v.mac] ) m[v.mac].users.push(v);
				});

				//Определяем тип порта
				_.map(ipoe, function(v){ v.device_port = parseInt(v.device_port) });
				_.map(out, function(v,k){
				    v.port_type = (_.findWhere(ipoe, { device_port: parseInt(v.port) })) ? (( _.findWhere(v.users, { connection_type: 'ppp' }))?'PPP-IPoE-access' :'IPoE-access'): 'PPP-access';
				});

				let win = window.open('', '', 'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no');
				let tpl = _.template(printTpl);

				win.document.write(tpl({ ports: out, _: _, model: me.model.toJSON() }));
				win.print();
			    });
			});
		    }});

		    if( !_.isEmpty(array)) context.show(e, array);
		})
	});

        //Контекстное меню порта
        var pModel = Backbone.Model.extend({
          initialize: function() {
            this.on('error',function(a, e) {
                var data = JSON.parse(e.responseText) || {};
                common.notifyError('"' + data.name + '" ' + (data.text_ru || data.text));
             },this);
          }
        });

        $sel.on('contextmenu', 'div.port', function(e) {
          e.preventDefault();
	  var port = $(e.currentTarget).closest('.port-group').attr('attr');

	   var array = [];
	    array.push({ name: 'Название', func: function(){
		var model = new pModel({ id: me.smodel.get('device') });
    		model.url = darsan.makeURL(prefix, 'device', '/' + me.smodel.get('type') + '/' + me.smodel.get('device')) + '/port/' + port + '/tag';
        	model.fetch({
        	    success: function() {
            		var tag = prompt('Название порта', model.get('tag'));
            		if (tag == null) return;
            		model.save({ tag: tag });
        	    }
        	});
	    }});

	    if (permission.can_write("device.data")){

		var model = new pModel({ id: me.smodel.get('device') });
        	model.url = darsan.makeURL(prefix, 'device', '/device/' + me.smodel.get('device') + '/port/' + port + '/status');
        	model.fetch({
        	    success: function() {
			var name = 'Включить порт', status = 1;

			if(model.get('status')){
			    name = 'Выключить порт';
			    status = 0;
			}

		        array.push({ name: name, func: function(){
			    model.save({ status: Boolean(status) }, { patch: true,
				success: function(){
				    me.smodel.trigger('change:device');
				}
			    });
			}});

			context.show(e, array);

		    },
		    error: function() {
			context.show(e, array);
		    }
		});

	   }else{
		context.show(e, array);
	   }
        });

	//Переход на абонента из окна состояния
        $sel.on('click', 'a#uid', function() {
	    $sel.closest('.ui-dialog-content').dialog('close');
        });

	//Ресайз при изменеии девайса
//	$sel.bind("DOMSubtreeModified", function(){ me.setSubModuleSize.call(me); });

	//Изменение port
	this.smodel.on('change:port', me.setPortsActive, this);

	//Изменение девайса
	this.smodel.on('change:type change:device', function(){

	    if (!this.smodel.get('device')) return console.error('Paremeter "device" not defined!');
	    if (!this.smodel.get('type')) return console.error('Parameter "type" not defined!');

	    var d;
	    //Загружаем новые данные

	    //Если тип PONTREE загружаем данные из PON
	    if( this.smodel.get('type') == 'pontree' ){
		d = [ darsan.get(prefix, 'device', '/pon/' + me.smodel.get('device')), darsan.get(prefix, 'client', '/srv/device/' + me.smodel.get('device')+'/clients') ];

	    //Если любой другой, используем тип из ссылки
	    }else{
		d = [ darsan.get(prefix, 'device', '/' + me.smodel.get('type') + '/' + me.smodel.get('device')), darsan.get(prefix, 'client', '/srv/device/' + me.smodel.get('device')+'/clients') ];
	    }

	    //Устраняем мигания
	    $sel.addClass('loading').empty();
	    this.model.clear();

	    $.when.apply($, d).always(function(data1, data2) {
		if (data1) {

		    data1.bind_uid = {};
		    _.map(data2, function(v){ 
			if( !_.isArray(data1.bind_uid[v.device_port]) ) data1.bind_uid[v.device_port] = [];
			data1.bind_uid[v.device_port].push(v);
		    });

		    //Наполняем модель
		    me.model.set(data1);
		    me.model.trigger('sync');

		    //Если устройство не pontree
		    if( me.smodel.get('type') == 'pontree' ){

			//Меняем заголовк у окна
			me.$el.closest('.ui-dialog').find('.ui-dialog-title').html(me.model.get('name'));

		    }else{

			//Меняем заголовк у окна
			me.$el.closest('.ui-dialog').find('.ui-dialog-title').html(me.model.get('name')+' ('+me.model.get('ip')+')' );

			//Рисуем девайс
			$sel.html(me.template({ model: me.model.toJSON(), advData: advData, common: common, type: me.smodel.get('type'), permission: permission })).removeClass('loading'); 
			me.setPortsActive();
		    }
		}

		$sel.removeClass('loading');
	    });

	}, this);

	//Изменение типа девайса
	this.smodel.on('change:type', function()
	{

	    //Находим модули, активные только для этого типа устройств
	    var tabs_alowed = [];
    	    switch (this.state.type) {
        	case 'switch':
        	    tabs_allowed = ['deviceFdb','deviceFdbOld','devicePortGraph','devicePortDiag','deviceGraph'];
        	break;
        	case 'pon':
        	    tabs_allowed = ['deviceFdb','deviceFdbOld','devicePortGraph','deviceGraph','ponTree'];
        	break;
        	case 'pontree':
        	    tabs_allowed = ['ponTree'];
        	break;
        	default:
		    tabs_allowed = ['deviceFdb', 'deviceFdbOld', 'devicePortGraph', 'deviceGraph'];
		break;
	    }

	    me.setTabs( tabs_allowed );
	    if( !_.contains( tabs_allowed, this.smodel.get('tab') )){
		    this.smodel.set({ tab: _.first(tabs_allowed) });
	    }else{
		    this.smodel.trigger('change:tab');
	    }
	}, this);

	//Изменение таба
	this.smodel.on('change:tab', function(){
	    //Получаем модуль
	    var mod = this.modules[this.smodel.get('tab')];
	    if (!mod) return console.error('Module not found!');

	    //Отключаем старый && атачим модуль
	    this.$el.find('#nav li').removeClass('active');
      this.$el.find('a[url="' + mod.name + '"]').closest('li').addClass('active');

	    //Отключаем старый && атачим модуль
	    var $el = this.$el.find('div#module');
	    $el.children().detach();

	    $el.html(mod.$el);

	}, this);

	//Меняем состояние субмодулей
	this.smodel.on('change', function(){
	    //Получаем модуль
	    var mod = this.modules[this.smodel.get('tab')];
	    if (!mod) return console.error('Module not found!');

	    //Устанавливаем  значение субмодуля
	    mod.setState( this.smodel.toJSON() );

	}, this);
      },
      
      setState: function(state) {
      	var me = this;

      	//Получаем состояния (URL) если не передано в state
        var param = ['port','tab'];
      	var urlState = common_dev.urlData2.get(param);

      	_.map(urlState, function(v,k){ 
    	    if( _.contains(param,k) && !state[k]) state[k] = urlState[k]; 
        });

      	//Устанавливаем состояние
      	this.state = state;
      	prefix = state.prefix||config.domain;

      	this.smodel.set(state);

	//Ресайз модуля при изменеии размера диалога
//	me.$el.closest('.ui-dialog').on('resize', function(){ me.setSubModuleSize.call(me) });

      },
    });
  }
);

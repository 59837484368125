define([
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',
    'common/visual/visual',
    'common/dialog-bs/dialog',
    'common/popover/popover',
    'device/common',
    'common',
    'device/status/modules/pon_tree/deviceGraph',

    'text-loader!device/status/modules/pon_tree/deviceInfo.tpl',
    'text-loader!device/status/modules/pon_tree/deviceRow.tpl',
    'text-loader!device/status/modules/pon_tree/userRow.tpl',
    'text-loader!device/status/modules/pon_tree/treeRow.tpl',
    'text-loader!device/status/modules/pon_tree/main.tpl'], 
    function($, _, Backbone, darsan, visual, dialog, popover, common_dev, common, deviceGraph, deviceInfoTpl, deviceRowTemplate, userRowTemplate, treeRowTemplate, mainTemplate) {

    var prefix;
    var advData, lData = {};
    lData.period = [
      { id: 'hour6', name: '6 часов' },
      { id: 'hour12', name: '12 часов' },
      { id: 'day', name: 'Сутки' },
      { id: 'day2', name: 'Двое суток' },
      { id: 'week', name: 'Неделя' },
      { id: 'month', name: 'Месяц' },
    ];

    var model = Backbone.Model.extend({});
    var collection = Backbone.Collection.extend({
      model: model,
      initialize: function(array, param) {
        this.smodel = param.smodel;
      }
    });

    var treeAbonModel = Backbone.Model.extend({
      initialize: function() {
        var me = this;
        this.on('ping', function() {
          if (!me.get('ip')) return;

          if (me.ws) {
            me.ws.close();
            delete me.ws;
          }

          me.ws = new WebSocket(config.ping_server + '/ip/' + me.get('ip') + '?packet=1400');
          me.ws.onclose = function(e) {};

          var k = 0,
          array = [];
          me.set({ ping_result: array });

          me.ws.onmessage = function(e) {

            k++;
            array.push(JSON.parse(e.data));

            me.set({ ping_result: array }, { silent: true });
            me.trigger('change:ping_result change');

            if (k >= 5) {
              me.ws.close();
              delete me.ws;
              return;
            }
          };
        });
      }
    });

    var treeAbonColl = Backbone.Collection.extend({
      model: treeAbonModel,
      comparator: function(m1, m2){
	  var dir = 1;

          var v1 = m1.get('login') || '', v2 = m2.get('login') || '';
	  if( !v1 ) return 1;

          if (dir == 1) {
            return String(v1).localeCompare(v2);
          } else {
            return String(v2).localeCompare(v1);
          }

	  return 0;
      }
    });


    var viewTreeAbonRow = Backbone.View.extend({
	tagName: 'li',
	className: 'node expand_leaf abon',
	template: _.template(userRowTemplate),
	initialize: function(){
	    this.listenTo(this.model, 'change', this.render);
	},
	render: function(){
	    this.$el.html( this.template({ model: this.model.toJSON(), _: _, common: common }) );
	    return this;
	},
	toUid: function(){
	    //Закрываем предыдущий
	    dialog.close('device-status-window');
	},
	toPing: function(e) {
	    e.preventDefault();
	    e.stopPropagation();

	    if (this.model.ws) return;
	    this.model.trigger('ping');
	},
	toPingResult: function(e) {
	    e.preventDefault();
	    e.stopPropagation();
	    if (this.model.ws) return;
	    this.model.unset('ping_result');
	},
	events:{
	    'click #uid':'toUid',
	    'click #ping':'toPing',
	    'click a#ping_result': 'toPingResult'
	}
    });

    var viewTreeAbon = Backbone.View.extend({
	tagName: 'ul',
	className: 'container',
	initialize: function(){
	    this.listenTo(this.collection, 'sort', this.render);
	},
	render: function(){
	    var me = this;

	    if (!this.oldView) this.oldView = {};
            _.each(this.oldView, function(v) {
                v.$el.detach();
	    },this);

	    //Подключаем виевы
	    var currView = {},array = [];

            //Отображение коллекции
	    this.collection.forEach(function(model) {

		 var view = this.oldView[model.cid] || new viewTreeAbonRow({ model: model }).render();
	         currView[model.cid] = view;
	         array.push(view.el);

		if( model.get('other') ){

		    _.forEach(model.get('other'), function(v){
			v.other = [];
			var model  = new treeAbonModel(v);
			var view = this.oldView[model.cid] || new viewTreeAbonRow({ model: model }).render();

			view.$el.addClass('other');

			currView[model.cid] = view;
			array.push(view.el);
		    }, this);

		}
		 view.$el.removeClass('is_last');

	    }, this);

	    //Уничтожаем неиспользуемые
	    _.each(this.oldView,function(v, k) {
		if (!currView[k]) v.remove();
            },this);

	    this.oldView = currView;

	    var l = _.last(array);
	    if( l ) $(l).addClass('is_last');

	    //Вставляем контент
	    this.$el.html( array );
	    return this;
	},
    });


    var _timeout;
    var viewTreeDeviceRow = Backbone.View.extend({
	tagName: 'li',
	className: 'node expand_closed',
	template: _.template(deviceRowTemplate),
	initialize: function(){

	    this.collection = new treeAbonColl();
	    this.collection.smodel = this.model.collection.smodel;

	    //Передаем ссылку на коллекцию для вышестоящей модели
	    this.model._collection = this.collection;

	    var hash = {};

	    //Читаем маки из онушек
	    _.map(this.model.get('fdb'), function(v){ hash[v.mac] = v; });
	    _.map(this.model.get('old_fdb'), function(v){ hash[v.mac] = v; });

	    //Наполняем коллекцию с маками
	    this.collection.reset(_.values(hash));

	    //Открываем дерево, если есть порт онушки
	    if( this.collection.smodel.get('onu') == this.model.get('onu') ){
		this.$el.removeClass('expand_closed').addClass('expand_open');

		var me = this;
		if('scrollIntoView' in  me.$el.get(0)) setTimeout(function(){ me.$el.get(0).scrollIntoView({ block: "center", behavior: "auto" }) }, 500);
	    }

	},
	render: function(){
	    var me = this;

	    this.$el.html( this.template({ model: this.model.toJSON(), _: _ }) );
	    this.$el.append( new viewTreeAbon({ collection: this.collection }).render().el );

	    if( this.collection.isEmpty() ){
		this.$el.addClass('expand_leaf');
	    }

	    return this;
	},
	toUsers: function(e){
	    var $el = $(e.currentTarget);

	    //Состояние открыто/закрыто
	    if( $el.closest('.node').hasClass('expand_open') ){
		//Меняем состояние (URL);
		common_dev.urlData2.set({ onu: undefined });
	    }else{
		//Меняем состояние (URL);
		common_dev.urlData2.set({ onu: this.model.get('onu') });
	    }

	},
	
	toGraph: function(e){
	    e.preventDefault();
	    var d = dialog.showComponent( 
	      "device/status/modules/OnuBoard", this.collection.smodel.get('head_name') + "  →  " + this.model.get('name'),
			  { mac: this.model.get('mac'),  device: this.model.get('device'), prefix: prefix  },
			  { id: 'onu-graph', width: '900px', height: window.innerHeight - 100 }
			); 
	},
	
	toPingAll: function(e) {
	    e.preventDefault();
	    e.stopPropagation();

	    this.collection.map(function(model){ model.trigger('ping'); });
        },
	toPowerAll: function(e) {
	    e.preventDefault();
	    e.stopPropagation();
	    var me = this;

	    var array = me.collection.map(function(m){ return m.get('uid') });
	    darsan.get(prefix, 'client', '/client', { query: 'entity IN ('+_.compact(array).join(',')+') AND PowerSupply=TRUE' }).done(function(data){
		_.forEach(data, function(v){

		    me.collection.forEach(function(m){
			if(m.get('entity') == v.entity || m.get('uid') == v.entity ) m.set({ powerSupply: true });
		    });

		});
	    });
        },
	toInfo: function(e){
	    var $el = $( _.template(deviceInfoTpl)({ model: this.model.toJSON(), diag_message: $(e.currentTarget).attr('diag-message'), common: common }) );

	    $el.on('click', '#screenShot', function(){
		common.printScreen($el);
	    }).one('mouseover', function(){

		clearTimeout(_timeout);
		$el.one('mouseleave', function(){
		    popover.close();
		});
	    });

	    clearTimeout(_timeout);
	    popover.show(e, { placement: 'right', content: $el });
	},
	toInfoClose: function(e){
	    _timeout = setTimeout(popover.close, 500);
	},
	events:{
	    'click #info': 'toInfo',
	    'mouseleave #info': 'toInfoClose',
	    'click #users': 'toUsers',
	    'click a#ping_all': 'toPingAll',
	    'click a#power_all': 'toPowerAll',
	    'click #graph':'toGraph'
	}
    });

    var viewTreeDevice = Backbone.View.extend({
	tagName: 'ul',
	className: 'container',
	initialize: function(){

	    var me = this;
	    this.collection.on('sync', function(){

		//Резолвим мак адреса
		var hash = {};

		//Читаем маки из онушек
		this.collection.forEach(function(model){
		    _.map(model.get('fdb'), function(v){ hash[v.mac] = 1; });
		    _.map(model.get('old_fdb'), function(v){ hash[v.mac] = 1; });
		});

	        darsan.post(prefix, 'client', '/clients-from-macs', { macs: _.keys(hash).join(',').replace(/:/g, '') }).then(function(data) {
		    var hash = {};

		    //Ищем сначала активные сессии, если нет то добавляем неактивные
		    _.map(data, function(v){

			//Добавляем дубликаты мак адресов
			if( hash[v.mac] ){
			    if( !hash[v.mac].other ) hash[v.mac].other = {};

			    if( !hash[v.mac].other[v.login] ) hash[v.mac].other[v.login] = v;
			    if( !hash[v.mac].other[v.login].duration ) hash[v.mac].other[v.login] = v;
			}

			if( !hash[v.mac] ) hash[v.mac] = v;
			if( !hash[v.mac].duration ) hash[v.mac] = v;
		    });

		    //Записываем результат обратно
		    me.collection.forEach(function(model){
			_.map(model.get('fdb'), function(v){ _.extend(v, hash[v.mac]); });
			_.map(model.get('old_fdb'), function(v){ _.extend(v, hash[v.mac]); });
		    });

		    //Рендерим результат
		    me.render();
		}, function(){

		    //Рендерим результат
		    me.render();
		});

	    }, this);
	},
	render: function(){
	    var array = [];

	    this.collection.forEach(function(model){
		model.trigger('change');
		var view = new viewTreeDeviceRow({ model: model }).render();
		array.push(view.el);
	    });

	    var l = _.last(array);
	    if( l ) $(l).addClass('is_last');

	    this.$el.html( array );
	    return this;
	}
    });

    var treeDeviceMod = Backbone.Model.extend({
	initialize: function(){
	    this.on('change', function(){
		var _state = {};
		_state.temperature = ( this.get('temperature') > 50 ) ? (( this.get('temperature') > 70 ) ? 2 : 1 ) : 0 ;
		_state.olt_rx = ( this.get('olt_rx') < -26 ) ? (( this.get('olt_rx') < -30 ) ? 2 : 1 ) : 0 ;
		_state.onu_rx = ( this.get('onu_rx') < -26 ) ? (( this.get('onu_rx') < -30 ) ? 2 : 1 ) : 0 ;
		this.set({'_state': _state });
	    });
	}
    });

    var treeDeviceColl = Backbone.Collection.extend({
	model: treeDeviceMod
    });

    var viewTreeRow = Backbone.View.extend({
	tagName: 'li',
	className: 'node expand_closed',
	template: _.template(treeRowTemplate),
	initialize: function(){
	    this.collection = new treeDeviceColl();
	    this.collection.url = darsan.makeUrl(prefix,'device', '/' + this.model.get('type')+ '/' +this.model.get('device') + '/tree/' + this.model.get('id')+'/onu');
	    this.collection.smodel = this.model.collection.smodel;

	    //Открываем дерево, если есть tree
	    if( this.collection.smodel.get('tree') == this.model.get('id') ){
		this.$el.removeClass('expand_closed').addClass('expand_open');
		this.toLoadTree({ currentTarget: this.$('#device') });
	    }
	},
	render: function(){
	    this.$el.html( this.template({ model: this.model.toJSON() }) );
	    this.$el.append( new viewTreeDevice({ collection: this.collection }).render().el );

	    return this;
	},
	toLoadTree: function(){
	    var me = this;
	    this.$('#device').addClass('expand_loading');

	    //Наполняем коллекцию
	    this.collection.fetch({ success: function(){
		me.$('#device').removeClass('expand_loading');
	    }, error: function(){
		me.$('#device').removeClass('expand_loading');
	    }});
	},
	toTree: function(e){
	    var me = this;
	    var $el = $(e.currentTarget);

	    //Состояние открыто/закрыто
	    if( $el.closest('.node').hasClass('expand_open') ){
		//Меняем состояние (URL);
		common_dev.urlData2.set({ tree: undefined, onu: undefined });
	    }else{
		//Меняем состояние (URL);
		common_dev.urlData2.set({ tree: this.model.get('id'), onu: undefined });
		this.toLoadTree();
	    }
	},
	toShowAll: function(){
	    if( this.model.get('_expand_all') ){
		this.$('.expand_open').removeClass('expand_open').addClass('expand_closed');
	    }else{
		this.$('.expand_closed').removeClass('expand_closed').addClass('expand_open');
	    }
	    this.model.set({_expand_all: !this.model.get('_expand_all') });
	},
	toPingAll: function(){
	    this.$('.expand_closed').removeClass('expand_closed').addClass('expand_open');
	    this.model.set({ _expand_all: 1 });

	    this.collection.forEach(function(model){
		if( model._collection ) model._collection.map(function(model){ model.trigger('ping'); });
	    });
	},
	events:{
	    'click #device':'toTree',
	    'click #showAll':'toShowAll',
	    'click #pingAll':'toPingAll'
	}
    });

    var viewTree = Backbone.View.extend({
	tagName: 'ul',
	className: 'container',
	initialize: function(){
	    this.collection.on('reset', this.render, this);
	},
	render: function(){
	    var array = [];

	    this.collection.forEach(function(model){
		var view = new viewTreeRow({ model: model, smodel: model.collection.smodel }).render();
		array.push(view.el);
	    }, this);

	    var l = _.last(array);
	    if( l ) $(l).addClass('is_last');

	    this.$el.html( array );
	    return this;
	}
    });


    return Object.create(visual).extend({
      title: 'Дерево',
      name: 'ponTree',
      icon: 'tree-deciduous',
      deferred: [],
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);
	this.$el.html( mainTemplate );

        this.smodel = new Backbone.Model();
	me.collection = new Backbone.Collection();
	me.collection.smodel = this.smodel;

	me.$('.pon_tree').html( new viewTree({ collection: me.collection }).render().el );

	//Устраняем мигание/Очищаем коллекцию
	this.smodel.on('all', function(v) {
	  if ( v == 'change:device' || v == 'change:tree' ) me.collection.reset();
	});

        this.smodel.on('change:device change:tree', function() {

	    //Отменяем предыдущие запросы
	   _.map(me.deferred, function(v){ v.fail() });
	   me.deferred = [];

	    //Получаем данные о деревьях
	    if( this.smodel.get('type') == 'pontree' ){

		var tree = me.smodel.get('tree');
		me.deferred.push(darsan.get(prefix, 'device', '/pontree/'+tree).done(function(t){

		    //Подменяем заголовок окна
	           $('#device-status-window').parent().find('.ui-dialog-title').html(me.title + ' ' + t.name);

		    var device = t.head;
		    me.deferred.push(darsan.get(prefix, 'device', '/pon/' + device +'/trees').done(function(b) {

			var v = _.findWhere(b, { tree: String(t.tree) })||_.findWhere(b, { tree: parseInt(t.tree) });
			if( v ){ 
			    me.smodel.set({ tree: v.tree, head_name: v.head_name }, {silent: true })
			    me.collection.reset([{ id: v.tree, type: 'pon', device: device, name: "<b>" + t.name + "</b>" + " <small title='Количество ONU' class='text-muted' style='cursor:help;vertical-align:text-top;'>(" + v.onu_count + ")</small>" }]);
			    me.$('.pon_tree').find('#device').css({ 'pointer-events': 'none' });
			}
		    }));

		}));

	    }else{
		me.deferred.push(darsan.get(prefix, 'device', '/' +me.smodel.get('type') +'/' +me.smodel.get('device') +'/trees').done(function(b) {
		    me.smodel.set({ head_name: (_.first(b)||{}).head_name }, {silent: true });
		    me.collection.reset( _.map(b, function(v){ return { id: v.tree, type: me.smodel.get('type'), device: me.smodel.get('device'), name: "<b>" + v.tree + "</b>" + " <small title='Количество ONU' class='text-muted' style='cursor:help;vertical-align:text-top;'>(" + v.onu_count + ")</small>" }}) );
		}));
	    }
        }, this)

	this.$el.on('click', tree_toggle);
	function tree_toggle(event) {

	    event = event || window.event
	    var clickedElem = event.target || event.srcElement

	    if (!hasClass(clickedElem, 'expand')) {
		return // клик не там
	    }

	    // Node, на который кликнули
	    var node = clickedElem.parentNode
	    if (hasClass(node, 'expand_leaf')) {
		return // клик на листе
	    }

            // определить новый класс для узла
	    var newClass = hasClass(node, 'expand_open') ? 'expand_closed' : 'expand_open';

            // заменить текущий класс на newClass
	    // регексп находит отдельно стоящий open|close и меняет на newClass
            var re =  /(^|\s)(expand_open|expand_closed)(\s|$)/
	    node.className = node.className.replace(re, '$1'+newClass+'$3')
	}


	function hasClass(elem, className) {
	    return new RegExp("(^|\\s)"+className+"(\\s|$)").test(elem.className)
	}

      },
      setState: function(state) {
        var me = this;

	//Получаем состояния (URL) если не передано в state
	var param = ['onu', 'tree'];
	var urlState = common_dev.urlData2.get(param);
	_.map(urlState, function(v,k){ 
	    if( _.contains(param,k) && !state[k]) state[k] = urlState[k]; 
	});

	//Значения по умолчанию
	_.defaults(state, { onu: undefined, tree: undefined });

        me.state = state;
	prefix = state.prefix||config.domain;

	this.smodel.set(state);
      }
    });
  }
);
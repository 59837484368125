import { render, staticRenderFns } from "./TrafficBoard.vue?vue&type=template&id=62624100&"
import script from "./TrafficBoard.vue?vue&type=script&lang=js&"
export * from "./TrafficBoard.vue?vue&type=script&lang=js&"
import style0 from "./TrafficBoard.vue?vue&type=style&index=0&id=62624100&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
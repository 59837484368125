var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DeviceGraph", {
    attrs: {
      title: _vm.title,
      yAxisTitle: _vm.yAxisTitle,
      type: _vm.type,
      series0: _vm.series0,
      series1: _vm.series1,
      values0: _vm.values0,
      values1: _vm.values1,
      upside1: true
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }